import {FormControl, Table} from "react-bootstrap";
import {PercentToNumber} from "../../utils/PercentToNumber";
import React from "react";

export function ItemsLightTable({items, readOnly, changeItem, deleteItem, small}) {

    function updateChannel(e, i, ch) {
        if (e.target.value > 100) return;
        changeItem(i, ch, e.target.value)
    }

    return <Table bordered className="overflow-hidden">
        <thead className={"text-center"}>
        <tr>
            <th className={small ? "p-0" : ""}>#</th>
            <th className={small ? "p-0" : ""}>CH1</th>
            <th className={small ? "p-0" : ""}>CH2</th>
            <th className={small ? "p-0" : ""}>CH3</th>
            <th className={small ? "p-0" : ""}>CH4</th>
            <th className={small ? "p-0" : ""}>Period</th>
        </tr>
        </thead>
        <tbody className={"text-center"}>
        {
            items.map((item, i) => <tr key={`item-${i}`}>
                <td className={small ? "p-0" : ""}>{i + 1}</td>

                <td className={"p-0"} style={{backgroundColor: `rgb(112, 213, 255, ${PercentToNumber(item?.ch1)})`}}>
                    <FormControl className={"p-0 text-center text-white"} plaintext type="number"
                                 size={"sm"}
                                 readOnly={readOnly}
                                 min={0} max={100}
                                 value={item?.ch1 || 0}
                                 onChange={e => updateChannel(e, i, "ch1")}/>
                </td>
                <td className={"p-0"} style={{backgroundColor:  `rgb(255, 127, 182, ${PercentToNumber(item?.ch2)})`}}>
                    <FormControl className={"p-0 text-center text-white"} plaintext type="number"
                                 size={"sm"}
                                 readOnly={readOnly}
                                 min={0} max={100}
                                 value={item?.ch2 || 0}
                                 onChange={e => updateChannel(e, i, "ch2")}/>
                </td>
                {/* #2953cb */}
                <td className={"p-0"} style={{backgroundColor: `rgb(41, 83, 203, ${PercentToNumber(item?.ch3)})`}}>
                    <FormControl className={"p-0 text-center text-white"} plaintext type="number"
                                 size={"sm"}
                                 readOnly={readOnly}
                                 min={0} max={100}
                                 value={item?.ch3 || 0}
                                 onChange={e => updateChannel(e, i, "ch3")}/>
                </td>
                <td className={"p-0"} style={{backgroundColor: `rgb(255, 80, 80, ${PercentToNumber(item?.ch4)})`}}>
                    <FormControl className={"p-0 text-center text-white"} plaintext type="number"
                                 size={"sm"}
                                 readOnly={readOnly}
                                 min={0} max={100}
                                 value={item?.ch4 || 0}
                                 onChange={e => updateChannel(e, i, "ch4")}/>
                </td>
                <td className={"p-0"}>
                    <FormControl className={"p-0 text-center text-white"} plaintext type="number"
                                 size={"sm"}
                                 readOnly={readOnly}
                                 min={0} max={2880}
                                 value={item?.period || 0}
                                 onChange={e => {
                                     if (e.target.value > 2880) return;
                                     changeItem(i, "period", e.target.value);
                                 }}/>
                </td>
                {
                    readOnly
                        ? <td className={small ? "p-0" : ""}><i className={"fa fa-remove text-muted cursor-pointer"}/></td>
                        : <td className={small ? "p-0" : ""}><i className={"fa fa-remove text-red cursor-pointer"}
                                 onClick={() => deleteItem(i)}/></td>
                }
            </tr>)
        }
        </tbody>
    </Table>
}
