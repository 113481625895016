import React, {useEffect, useState} from 'react';
import {routeStore} from "../../store/RouteStore";
import {observer} from "mobx-react-lite";
import Select from "react-select";
import {Button, Col, Container, Jumbotron, Row} from "react-bootstrap";
import {rootStore} from "../../store/RootStore";
import {api} from "../../api/restConfig";
import ReportChart from "../components/report/ReportChart";

export default observer(function Reports() {

    const {projects} = (rootStore);

    const [selectedCycle, setSelectedCycle] = useState(null);
    const [selectedParameter, setSelectedParameter] = useState(null);
    const [selectedRemote, setSelectedRemote] = useState(null);

    const [data, setData] = useState();
    const [max, setMax] = useState([]);
    const [min, setMin] = useState([]);
    const [avg, setAvg] = useState([]);

    const [begin, setBegin] = useState([]);
    const [end, setEnd] = useState([]);
    const [phases, setPhases] = useState([]);

    const [remoteOptions, setRemoteOptions] = useState([]);

    useEffect(() => {
        routeStore.setLocation("Reports")
    }, []);

    useEffect(() => {
        if (!selectedCycle || !selectedParameter) return;

        let url = `chart/report?cycleID=${selectedCycle.value}&parameter=${selectedParameter.value}`;
        if (!!selectedRemote?.value) url += `&remoteID=${selectedRemote?.value}`

        api
            .get(url)
            .then(res => {
                setData(res.data.chart);
                setMin(res.data.min);
                setMax(res.data.max);
                setAvg(res.data.avg);
                setBegin(res.data.begin);
                setEnd(res.data.end);
                setPhases(res.data.phases);
            });

    }, [selectedCycle, selectedParameter, selectedRemote]);

    useEffect(() => {
        if (!selectedCycle) setSelectedRemote([]);
        else {
            setSelectedRemote(null);
            const remotes = rootStore.projects
                .flatMap(project => project.rooms)
                .find(room => room.cycles.map(cycle => cycle.id).includes(selectedCycle.value))
                .remotes.map(remote => {
                    return {
                        value: remote.id,
                        label: remote.name
                    }
                })

            setRemoteOptions(remotes);
        }
    }, [selectedCycle])

    return (
        <div className={"noselect"}>
            <Row>
                <Col xs={12} sm={4} md={3} className={"mt-2 mt-sm-0"}>
                    <div>
                        <h6 className="fs-18 font-w500 mb-3">Cycle</h6>
                        <Select
                            placeholder={"Select Cycle"}
                            value={selectedCycle}
                            onChange={setSelectedCycle}
                            options={projects.flatMap(project => {
                                return project.rooms.map(room => {
                                    return {
                                        label: `${project.name} | ${room.name}`,
                                        options: room.cycles.map(cycle => {
                                            return {
                                                value: cycle.id,
                                                label: cycle.name
                                            }
                                        })
                                    }
                                })
                            })}
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                            }}
                        />
                    </div>
                </Col>

                <Col xs={12} sm={4} md={3} className={"mt-2 mt-sm-0"}>
                    <div>
                        <h6 className="fs-18 font-w500 mb-3">Parameter</h6>
                        <Select
                            placeholder={"Select Parameter"}
                            value={selectedParameter}
                            onChange={setSelectedParameter}
                            options={[
                                {value: "T", label: "Temperature"},
                                {value: "CO2", label: "CO₂"},
                                {value: "RH", label: "Humidity"},
                                {value: "P", label: "Pressure"},
                                {value: "Tpcb", label: "Tpcb"},
                                {value: "Load", label: "Load"}
                            ]}
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                            }}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={4} md={3} className={"mt-2 mt-sm-0"}>
                    <div>
                        <h6 className="fs-18 font-w500 mb-3">Remote</h6>
                        <Select
                            isDisabled={!selectedCycle}
                            isClearable={true}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={"All Remotes"}
                            value={selectedRemote}
                            onChange={setSelectedRemote}
                            options={remoteOptions}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={3} className={"d-flex mt-2 mt-sm-0 justify-content-end align-items-end"}>
                    <Button
                        disabled={!selectedCycle || !selectedParameter}
                        size={"sm"}
                        className="mr-2 btn-square btn-sm text-nowrap"
                        variant="secondary"
                        onClick={() => {
                            api.request({
                                url: `chart/report/csv?cycleID=${selectedCycle.value}&parameter=${selectedParameter.value}`,
                                method: 'GET',
                                responseType: 'blob', // important
                            }).then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `${selectedCycle.label}-${selectedParameter.label}.csv`); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            })
                        }}
                    >
                        Download CSV
                        <span className="btn-icon-right py-2">
                            <i className="fa fa-download" />
                        </span>
                    </Button>
                </Col>
            </Row>

            <Row className={"mt-4"}>
                <Col>
                    {!!selectedCycle && !!selectedParameter && !!data &&
                        <ReportChart
                            initialData={data}
                            min={min} avg={avg}
                            max={max}
                            begin={begin}
                            end={end}
                            phases={phases}
                            cycle={selectedCycle.label}
                            parameter={selectedParameter.label}
                        />
                    }
                    {(!selectedCycle || !selectedParameter) &&
                        <Jumbotron fluid className="bg-secondary-light text-white">
                            <Container>
                                <h1>Choose cycle and parameter</h1>
                                <h3>for showing report chart</h3>
                            </Container>
                        </Jumbotron>
                    }
                </Col>
            </Row>
        </div>

    )
});
