import React, {useEffect, useState} from 'react';
import {Button, Col, Nav, Row} from "react-bootstrap";
import {routeStore} from "../../../store/RouteStore";
import {Link} from "react-router-dom";
import {api} from "../../../api/restConfig";
import {JumbotronPlaceholder} from "../../components/JumbotronPlaceholder";
import {observer} from "mobx-react-lite";
import {ProtocolComponent} from "./ProtocolComponent";

const Programs = () => {

    const [activeTab, setActiveTab] = useState("my");
    const activateTab = (tab) => setActiveTab(tab);

    const [protocols, setProtocols] = useState([]);
    const [shared, setShared] = useState([]);

    useEffect(() => {
        routeStore.setLocation("Programs")
        api.get('program')
            .then(res => {
                if (!!res.data) setProtocols(res.data);
            })
            .catch(err => console.error(err));

        api.get('program/shared')
            .then(res => {
                if (!!res.data) setShared(res.data);
            })
            .catch(err => console.error(err));
    }, [])

    function deleteProtocol(pid) {
        api.delete(`program/${pid}`)
            .then(__ => {
                const index = protocols.findIndex(p => p.id === pid);
                setProtocols(prevState => {
                    const state = [...prevState];
                    state.splice(index, 1);
                    return state;
                })
            })
            .catch(err => console.log(err));
    }

    function copyProgram(pid) {
        api.put(`program/${pid}`)
            .then(res => {
                if (!!res.data) setProtocols(res.data);
            })
            .catch(err => console.error(err));
    }

    const MyTab = () => {
        if (protocols.length === 0)
            return <JumbotronPlaceholder title={"Lets started"}
                                         text={"It's time to create your first Growing Protocol"}/>

        return protocols.map(protocol => {
            return <Link to={"programs/" + protocol.id}>
                <ProtocolComponent protocol={protocol} deleteProtocol={deleteProtocol} copyProgram={copyProgram} />
            </Link>
        })
    }

    const SharedTab = () => {
        return shared.map(protocol => {
            return <Link to={"programs/" + protocol.id}>
                <ProtocolComponent protocol={protocol} deleteProtocol={deleteProtocol} copyProgram={copyProgram} />
            </Link>
        })
    }

    // if (loaderStore.load) return <></>

    return (
        <>
            <Row className="page-titles mx-0">
                <Col sm={6} className="p-md-0">
                    <Nav as="ul" className="nav-pills light">
                        <Nav.Item as="li" key={"my"}>
                            <Nav.Link eventKey={"my"} active={activeTab === "my"}
                                      onSelect={eventKey => activateTab(eventKey)}>
                                <i className={`la la-home mr-2`}/>
                                My Protocols
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" key={"shared"}>
                            <Nav.Link eventKey={"shared"} active={activeTab === "shared"}
                                      onSelect={eventKey => activateTab(eventKey)}>
                                <i className={`la la-share-alt mr-2`}/>
                                Shared Protocols
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={6} className="p-md-0 justify-content-end mt-2 mt-sm-0 d-flex">
                    <Link to={"/programs/new"}>
                        <Button
                            href={"/programs/new"}
                            size={"sm"}
                            variant="secondary"
                            className="rounded mr-3 mb-sm-0 mb-2"
                            style={{height: 'fit-content'}}
                        >
                            <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create New Growing Protocol
                        </Button>
                    </Link>

                </Col>
            </Row>

            {activeTab === "shared" ? <SharedTab/> : <MyTab/>}


        </>
    )
}
export default observer(Programs);
