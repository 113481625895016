import React, {Fragment, useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from 'react-bootstrap';
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {rootStore} from "../../../store/RootStore";
import {routeStore} from "../../../store/RouteStore";
import {CreateCycleModal} from "../../components/cycle/CreateCycleModal";
import swal from "sweetalert";

const ProjectRoom = observer(function ProjectRoom() {

    const {projectID} = useParams();
    const {roomID} = useParams();

    const project = rootStore.getProject(projectID);
    const room = rootStore.getRoom(roomID);

    const [newCycle, setNewCycle] = useState(false);
    const [filter, setFilter] = useState(["NEW", "IN_FLIGHT", "SCHEDULED", "DONE", "TERMINATED"])

    function getFilter() {
        return cycle => filter.includes(cycle.status);
    }

    useEffect(() => {
        routeStore.setLocation("Projects")
    }, []);

    const statusMap = {
        NEW: "light",
        IN_FLIGHT: "info",
        SCHEDULED: "warning",
        DONE: "success",
        TERMINATED: "danger"
    }

    const statuses = [
        {
            name: "NEW",
            color: "light",
            tooltip: "Newly created, but not running cycles"
        },
        {
            name: "IN_FLIGHT",
            color: "info",
            tooltip: "Currently running cycles"
        },
        {
            name: "SCHEDULED",
            color: "warning",
            tooltip: "Cycles scheduled for the coming period"
        },
        {
            name: "DONE",
            color: "success",
            tooltip: "Successfully completed cycles"
        },
        {
            name: "TERMINATED",
            color: "danger",
            tooltip: "Cycles that were canceled during execution"
        },
    ]

    const delete_btn = (cycle) => (
        <OverlayTrigger
            key={`${cycle.id}-delete`}
            placement={"right"}
            overlay={
                <Tooltip id={`tooltip-right`}>
                    Delete cycle
                </Tooltip>
            }
        >
            <Button
                variant="outline-dark"
                disabled={cycle.status === "IN_FLIGHT"}
                className={`close ml-2`}
                style={{verticalAlign: "baseline", float: "inherit"}}
                onClick={(e) => {
                    e.preventDefault();
                    swal({
                        title: "Are you sure?",
                        text: "All collected statistics will be lost",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            room.deleteCycle(cycle.id);
                            swal("Remote has been removed from the Growing Area!", {
                                icon: "success",
                            });
                        }
                    })
                }}
            >
              <span>
                <i className={`mdi mdi-delete ${cycle.status !== "IN_FLIGHT" ? "text-danger" : "text-muted"}`}/>
              </span>
            </Button>
        </OverlayTrigger>

    );

    return (
        <Fragment>
            <Row className={"page-titles mx-0"}>
                <Col lg={12} className={"p-md-0 mt-2 mt-sm-0"}>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link}
                                         linkProps={{to: "/projects/" + projectID}}>{project?.name}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{room?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                <Col lg={6} className={"align-self-end"}>

                    <ToggleButtonGroup className={"btn-square mt-2"} size="sm" type={"checkbox"} value={filter}
                                       onChange={value => {
                                           setFilter(value);
                                       }}>

                        {statuses.map(status =>
                            <ToggleButton
                                key={status.name}
                                data-toggle="tooltip" data-placement="bottom" title={status.tooltip}
                                variant={`outline-${status.color}`}
                                className={"mr-1 px-1 px-sm-3"}
                                value={status.name}
                                type={"checkbox"}>
                                {status.name}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </Col>

                <Col lg={6} className={"justify-content-end d-flex p-0 mt-2"}>

                    <Button
                        variant="secondary"
                        className="rounded mx-3 mb-sm-0 mb-2"
                        onClick={() => setNewCycle(true)}>
                        <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create New Cycle
                    </Button>
                    <CreateCycleModal show={newCycle} setShow={setNewCycle} room={room}/>
                </Col>
            </Row>
            {/*--------------------------------------------------------*/}
            {room?.cycles.filter(getFilter()).map(cycle =>
                <Card key={cycle.id}>
                    <Card.Body as={Link} to={"/projects/" + project?.id + "/room/" + room?.id + "/cycle/" + cycle?.id}
                               className="project-info">

                        <Col xl={6} lg={6} sm={6} className={"my-2"}>
                            <p className={"text-primary mb-2"}>Cycle #{cycle?.id} {delete_btn(cycle)}</p>

                            <h4 className={"title font-w600 mb-2"}>{cycle?.name}</h4>
                            <div className={"text-dark"}>
                                <i className={"fa fa-clock-o mr-2 "}/>{cycle.startAtString}
                            </div>
                        </Col>

                        <Col xl={2} lg={6} sm={6} className={"my-2"}>
                            <div className="d-flex align-items-center">
                                <div className="power-ic">
                                    <i className="fa fa-leaf bg-warning" aria-hidden="true"/>
                                </div>
                                <div className="ml-3">
                                    <span className={"text-muted"}>End of cycle</span>
                                    <h5 className="mb-0 pt-1 font-w500 text-black">{cycle.harvestAtString}</h5>
                                </div>
                            </div>
                        </Col>

                        <Col xl={2} lg={6} sm={6} className={"my-2"}>
                            <div className="d-flex align-items-center">
                                <div className="power-ic">
                                    <i className="fa fa-bolt" aria-hidden="true"/>
                                </div>
                                <div className="ml-3">
                                    <span className={"text-muted"}>Consumed Electricity</span>
                                    <h5 className="mb-0 pt-1 font-w500 text-black">{cycle.kwh} kWh</h5>
                                </div>
                            </div>
                        </Col>

                        <Col xl={2} lg={6} sm={6} className={"my-2"}>
                            <div className="d-flex project-status align-items-center">
                                <Button variant={statusMap[cycle.status]}
                                        className={"status-btn"}>{cycle.status}</Button>
                            </div>
                        </Col>

                    </Card.Body>
                </Card>
            )}

        </Fragment>
    )
})
export default ProjectRoom;
