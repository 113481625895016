import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";

export function CreateCycleModal({show, setShow, room}) {

    const handleClose = () => setShow(false);
    const [name, setName] = useState("");
    const [error, setError] = useState();

    function create() {
        room.createCycle(name)
            .then(__ => handleClose())
            .catch(reason => {
                setError(reason?.message || "");
            });
    }

    return <Modal className="modal fade" show={show} onHide={setShow}>

        <Modal.Header closeButton>
            <Modal.Title>Create Cycle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onKeyPress={event => {
                if (event.code === "Enter") {
                    event.preventDefault();
                    create();
                }
            }}>
                <Form.Group>
                    <Form.Label className="text-black font-w500">Cycle Name</Form.Label>
                    <Form.Control maxLength="50" aria-describedby="nameHelp"
                                  onChange={e => setName(e.target.value)}/>
                    <Form.Text id="nameHelp" muted>
                        Cycle Name must be less than 50 characters long, and
                        must not contain special characters, or emoji.
                    </Form.Text>
                </Form.Group>
            </Form>
            {error && (
                <Alert
                    variant="danger"
                    className="solid alert-dismissible fade show"
                >
                    <strong>Error.</strong> {error}
                </Alert>
            )}
        </Modal.Body>

        <Modal.Footer>
            <Button
                block
                variant="secondary"
                onClick={create}>
                CREATE
            </Button>
        </Modal.Footer>

    </Modal>;
}
