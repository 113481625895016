import {api} from "../api/restConfig";
import {authStore} from "./AuthStore";
import {TOKEN} from "../api/api";

const {types} = require("mobx-state-tree");

const User = types.model("User", {
    id: types.number,
    username: types.string,
    role: types.string
})
    .actions((user) => ({
        setRole(role) {
            user.role = role;
        }
    }))

const UsersStore = types.model("UsersStore",
    {
        users: types.array(User),
        page: types.optional(types.number, 0),
        size: types.optional(types.number, 0),
        totalElements: types.optional(types.number, 0),
        totalPages: types.optional(types.number, 0),
        first: types.optional(types.boolean, false),
        last: types.optional(types.boolean, false)
    })
    .actions((store) => ({
        load() {
            store.searchUsers();
        },
        replace(page) {
            store.users = page.content;
            store.page = page.number;
            store.size = page.size;
            store.totalElements = page.totalElements;
            store.totalPages = page.totalPages;
            store.first = !!page.first;
            store.last = !!page.last;
        },
        pushToStart(user) {
            store.users.unshift(user);
        },
        async searchUsers(q, s, p) {
            fetchUsers(q, s, p).then(page => store.replace(page));
        },
        async addUser(user) {
            await createUser(user)
                .then(value => store.pushToStart(value));
        },
        async changeUserPassword(id, password, confirm) {
            const body = {id, password, confirm};
            return await api.patch("/user", body);
        },
        async changeUserRole(id, role) {
            const body = {id, role};
            return await api.put("/user", body)
                .then(value => {
                    const user = value.data;
                    const index = store.users.findIndex(x => x.id === user.id);
                    store.users[index].setRole(user.role);
                });
        },
        async loginAsUser(id) {
            let response = await api.post("/user/login/" + id);
            let token = response.data.token;
            await localStorage.setItem(TOKEN, token);
            authStore.setAuth(token);
            window.location.href = "/";
        }
    }));

export const usersStore = UsersStore.create();

async function createUser(user) {
    const response = await api.post('/user', {
        ...user
    });
    return response.data;
}

async function fetchUsers(query, sort, pageble) {
    let params = {};
    if (!!query) params.search = query;
    if (!!sort && !!sort.name) {
        params.sort = sort.name;
        params.dir = sort.direction;
    }
    if (!!pageble) {
        params.page = pageble.page;
        params.size = pageble.size;
    }

    const response = await api.get('/user', {params: params});
    return response.data;
}
