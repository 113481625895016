import {Button, Card, Col, Form, FormControl, Row, Table} from "react-bootstrap";
import {parameterIcon, parameterTitle} from "../../utils/ParameterHelper";
import {ItemsLightTable} from "../../components/phase/ItemsLightTable";
import React, {useEffect, useState} from "react";
import {newItemObject} from "../../utils/NewProtocolObject";
import {minutesToHuman} from "../../utils/TimeUtils";
import {PhaseNameSelector} from "./PhaseNameSelector";
import {profileStore} from "../../../store/ProfileStore";
import {toC, toF} from "../../utils/TemperatureConverter";

export const ProtocolPhase = ({phase, i, first, last, change, clone, remove, up, down, readOnly}) => {

    // let readOnly;

    function changeItem(i, ch, value) {
        phase.items[i][ch] = +value;
        computeDuration();
        change(phase);
    }

    function deleteItem(i) {
        phase.items.splice(i, 1);
        computeDuration();
        change(phase);
    }

    function computeDuration() {
        const loopSum = phase.items.map(i => i.period).reduce((p, c) => p + c, 0);
        setLoopDuration(minutesToHuman(loopSum));
        setPhaseDuration(minutesToHuman(phase.loops * loopSum));
    }

    const [loopDuration, setLoopDuration] = useState("-");
    const [phaseDuration, setPhaseDuration] = useState("-");

    useEffect(() => {
        computeDuration();
    }, [phase])

    function convertToF(parameterName, value) {
        return +(parameterName === "T" && profileStore.f? toF(value) : value)
    }

    function convertToC(parameterName, value) {
        return +(parameterName === "T" && profileStore.f? toC(value) : value)
    }

    return <Card style={{height: "fit-content"}}>

        <Card.Body style={{borderRadius: "1.375rem"}}
                   className={`project-info ${!!phase.phaseName ? phase.phaseName === 'Vega' ? 'bg-info-light' : 'bg-danger-light' : ''}`}>

            <Col md={6} className={"my-2 align-self-start"}>
                <Row>
                    <Col xs={2} sm={2} md={6} lg={2}>
                        <h4 className={"m-0 title font-w600"}>
                            <FormControl className={"p-0 text-primary"} plaintext type="text"
                                         size={"sm"}
                                         readOnly={readOnly}
                                         placeHolder={"Short Name..."}
                                         value={phase.name}
                                         onChange={e => {
                                             if (e.target.value.length > 4) return;
                                             phase.name = e.target.value.toUpperCase();
                                             change(phase);
                                         }}/>
                        </h4>
                    </Col>

                    <Col xs={4} sm={4} md={6} lg={4}>
                        {!readOnly &&
                            <PhaseNameSelector phaseName={phase.phaseName}
                                               onChange={e => {
                                                   phase.phaseName = e.label;
                                                   change(phase);
                                               }}/>
                        }
                    </Col>

                    <Col xs={6} sm={6} md={12} lg={6} className={"d-flex justify-content-end mt-md-2 mt-lg-0"}>
                        <Button
                            disabled={first}
                            size={"sm"}
                            hidden={readOnly}
                            variant="outline-info"
                            className="rounded ml-1"
                            style={{height: 'fit-content'}}
                            onClick={() => up()}
                        >
                            <i className="fa fa-arrow-up scale3" aria-hidden="true"/>
                        </Button>
                        <Button
                            disabled={last}
                            size={"sm"}
                            hidden={readOnly}
                            variant="outline-info"
                            className="rounded ml-1"
                            style={{height: 'fit-content'}}
                            onClick={() => down()}
                        >
                            <i className="fa fa-arrow-down scale3" aria-hidden="true"/>
                        </Button>

                        <Button
                            size={"sm"}
                            hidden={readOnly}
                            variant="outline-secondary"
                            className="rounded ml-1"
                            style={{height: 'fit-content'}}
                            onClick={() => clone()}
                        >
                            <i className="fa fa-clone scale3" aria-hidden="true"/>
                        </Button>

                        <Button
                            size={"sm"}
                            hidden={readOnly}
                            variant="outline-danger"
                            className="rounded ml-1"
                            style={{height: 'fit-content'}}
                            onClick={() => remove()}
                        >
                            <i className="fa fa-remove scale3" aria-hidden="true"/>
                        </Button>
                    </Col>
                </Row>

                <Form inline className="mb-2">
                    <Form.Label className="m-0">
                        Loops:
                    </Form.Label>
                    <FormControl className={"p-0 ml-2 text-white"} plaintext type="number" size={"sm"}
                                 readOnly={readOnly}
                                 min={1} max={9999}
                                 value={phase.loops}
                                 maxLength={2.5}
                                 style={{width: `2.5em`}}
                                 onChange={e => {
                                     if (e.target.value < 1) return;
                                     phase.loops = e.target.value;
                                     computeDuration();
                                     change(phase);
                                 }}/>
                </Form>

                <p className={"d-flex m-0 p-0 text-dark justify-content-between"}>
                    <span><span className="mdi mdi-timelapse scale2"/> Bioday Duration: {loopDuration || '-'}</span>
                    <span><span className="mdi mdi-timetable scale2"/> Phase Duration: {phaseDuration || '-'}</span>
                </p>

                <hr className={"m-0 mt-1 mb-2"}/>
                <h5 className={"m-0 mt-3"}>Parameters</h5>
                <Table size={"sm"}>
                    <thead>
                    <tr>
                        <th className={"p-0"}/>
                        <th className={"p-0"}>min</th>
                        <th className={"p-0"}>max</th>
                    </tr>
                    </thead>
                    <tbody>

                    {phase.parameters.map((parameter, index) => {
                        return <tr key={`phase-${i}-parameter-${parameter.name}`}>
                            <td className={"p-0 py-1"}>
                                <div className="micro-ic">
                                    {parameterIcon(parameter.name)}
                                    <span
                                        className={"text-primary ml-2"}>{parameterTitle(parameter.name)}</span>
                                </div>
                            </td>
                            <td className={"p-0"}>
                                <FormControl className={"p-0 text-white"} plaintext type="number" size={"sm"}
                                             readOnly={readOnly}
                                             min={0} max={9999}
                                             value={convertToF(parameter.name, parameter.min).toFixed(0)}
                                             maxLength={4}
                                             style={{width: `4em`}}
                                             onChange={e => {
                                                 phase.parameters[index]["min"] = convertToC(parameter.name, e.target.value);
                                                 change(phase);
                                             }}/>
                            </td>
                            <td className={"p-0"}>
                                <FormControl className={"p-0 text-white"} plaintext type="number" size={"sm"}
                                             readOnly={readOnly}
                                             min={0} max={9999}
                                             value={convertToF(parameter.name, parameter.max).toFixed(0)}
                                             maxLength={4}
                                             style={{width: `4em`}}
                                             onChange={e => {
                                                 phase.parameters[index]["max"] = convertToC(parameter.name, e.target.value);
                                                 change(phase);
                                             }}/>
                            </td>
                        </tr>
                    })}

                    </tbody>
                </Table>
            </Col>

            <Col md={6} className={"my-2 align-self-start "}>
                <div className={"d-flex align-items-center justify-content-between mb-2"}>
                    <h5 className={"m-0"}>Light</h5>
                    <Button
                        variant="secondary" size={"sm"}
                        hidden={readOnly}
                        className="rounded py-1"
                        onClick={() => {
                            phase.items = [...phase.items, newItemObject()];
                            change(phase);
                        }}>
                        <i className="fa fa-plus mr-3" aria-hidden="true"/>Add Item
                    </Button>
                </div>

                <ItemsLightTable small
                                 items={phase.items}
                                 readOnly={readOnly}
                                 changeItem={changeItem}
                                 deleteItem={deleteItem}/>

            </Col>

        </Card.Body>

    </Card>
}
