import React, {Fragment, useEffect, useState} from 'react';
import {routeStore} from "../../store/RouteStore";
import {Accordion, Button, Card, Col, Container, Dropdown, Jumbotron, Row} from "react-bootstrap";
import {CreateRoom} from "../components/room/CreateRoom";
import {EditRoom} from "../components/room/EditRoom";
import {observer} from "mobx-react-lite";
import swal from "sweetalert";
import Remote from "../components/remote/Remote";
import {rootStore} from "../../store/RootStore";

export default observer(function Devices() {

    useEffect(() => {
        routeStore.setLocation("Devices")
    }, [])

    const [activeDefault, setActiveDefault] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();

    function openEdit(room) {
        setSelectedRoom(room);
        setShowEdit(true);
    }

    return (
        <Fragment>
            <div className="row page-titles mx-0">
                <div className="col-sm-6 p-md-0">

                </div>
                <div className="col-sm-6 p-md-0 justify-content-end mt-2 mt-sm-0 d-flex">
                    <CreateRoom/>
                </div>
            </div>

            {showEdit && <EditRoom show={showEdit} setShow={setShowEdit} room={selectedRoom}/>}

            {rootStore.rooms?.length === 0 &&
                <Jumbotron fluid className="bg-secondary-light text-white">
                <Container>
                <h1>Now you can add your first Growing Area</h1>
                <p>Then distribute all of your devices to Growing Areas</p>
                <i className="fa fa-info-circle mr-2"/>
                If you not see any remotes, please connect them to software at first
                </Container>
                </Jumbotron>
            }

            <Accordion
                className="accordion accordion-primary"
                defaultActiveKey="0"
            >

                {rootStore.rooms?.map((room, i) => (
                    <div className="accordion__item" key={i}>
                        <Accordion.Toggle
                            as={Card.Text}
                            eventKey={`${i}`}
                            className={`accordion__header rounded-lg ${
                                activeDefault === i ? "" : "collapsed"
                            }`}
                            onClick={() =>
                                setActiveDefault(activeDefault === i ? -1 : i)
                            }
                        >
                            <span className="accordion__header--text">{room.name}</span>
                            <span className="accordion__header--indicator"/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${i}`}>
                            <div className="px-2 pt-0 pb-1 bg-primary-light">
                                <Row>
                                    <Col className="align-self-center">
                                        <h5 className={"text-muted m-2"}>{room.description}</h5>
                                    </Col>
                                    <Col>
                                        <div className="justify-content-end d-flex">
                                            <Dropdown>
                                                <Dropdown.Toggle className="m-2 i-false" variant="secondary light" size="sm">
                                                    <i className="fa fa-plus mr-2" aria-hidden="true"/>
                                                    Add Remote
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {rootStore.remotesWithoutRooms?.map(remote=> <Dropdown.Item onClick={() => {
                                                        rootStore.changeRemoteRoom(remote, room);
                                                    }}>{remote.name}</Dropdown.Item>)}
                                                    {rootStore.remotesWithoutRooms?.length === 0 && <Dropdown.Item disabled>No remotes to add</Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Button variant="primary" className="light sharp m-2 p-0" size="sm" onClick={() => {
                                                openEdit(room);
                                            }}>
                                                <i className="fa fa-pencil" aria-hidden="true"/>
                                            </Button>

                                            <Button variant="danger" className="light sharp m-2 p-0" size="sm" onClick={() => {
                                                swal({
                                                    title: "Are you sure?",
                                                    text:
                                                        "Once deleted, you will not be able to recover this room!",
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                }).then((willDelete) => {
                                                    if (willDelete) {
                                                        rootStore.deleteRoom(room.id)
                                                            .then(__ => swal("Room has been deleted!", {icon: "success"}))
                                                            .catch(reason => {
                                                                swal({
                                                                    title: "Error",
                                                                    text: reason?.message,
                                                                    icon: "error"
                                                                })
                                                            })
                                                    }
                                                })
                                            }}>
                                                <i className="fa fa-trash" aria-hidden="true"/>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>

                                {room.remotes.map(remote =>
                                    <Remote key={remote.id} remote={remote}/>
                                )}

                            </div>
                        </Accordion.Collapse>
                    </div>
                ))}
            </Accordion>

            {rootStore.remotesWithoutRooms.length !== 0 &&
                <>
                    <hr/>
                    <div className="col-sm-6">
                        <div className="welcome-text">
                            <h3>Remotes without room</h3>
                        </div>
                    </div>

                    {rootStore.remotesWithoutRooms.map(remote =>
                        <Remote key={remote.id} remote={remote}/>
                    )}
                </>
            }
        </Fragment>
    )
});
