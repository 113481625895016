import React, {useState} from "react";
import {Button, FormControl, InputGroup} from "react-bootstrap";

export const CycleName = ({cycle}) => {
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState(cycle?.name);

    return <h2 className="title-num text-white font-w700">
        {edit
            ? (
                <InputGroup style={{width: "fit-content"}} className={"border-bottom"}>
                    <FormControl
                        className={"border-0 text-white font-w700 p-0"}
                        plaintext={edit}
                        size={"lg"}
                        defaultValue={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text
                            className={"cursor-pointer bg-transparent border-0"}
                            onClick={() => setEdit(false)}
                        >
                            <i className={`fa fa-remove scale2 text-danger`}/>
                        </InputGroup.Text>
                        <InputGroup.Text
                            className={"cursor-pointer bg-transparent border-0"}
                            onClick={() => {
                                cycle.rename(name).then(() => setEdit(false));
                            }}>
                            <i className={`fa fa-check scale2 text-success`}/>
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            )
            : cycle?.name}
        <Button
            hidden={edit}
            variant="outline-dark"
            className={`close ml-2`}
            style={{verticalAlign: "baseline", float: "inherit"}}
            onClick={() => setEdit(true)}
        >
            <span><i className={`fa fa-edit`}/></span>
        </Button>
    </h2>
}
