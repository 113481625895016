import React, {Fragment, useEffect, useState} from 'react';
import {routeStore} from "../../store/RouteStore";
import {Button, Dropdown, Table} from "react-bootstrap";
import {usersStore} from "../../store/UsersStore";
import {observer} from "mobx-react-lite";
import swal from "sweetalert";
import {CreateUser} from "../components/user/CreateUser";
import {ChangePasswordModal} from "../components/user/ChangePasswordModal";

const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
    </svg>
);

const INITIAL_PAGE = {
    page: 0,
    size: 10,
};

export default observer(function Users() {
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    function openChangePasswordModal(user) {
        setSelectedUser(user);
        setChangePasswordModal(true);
    }

    useEffect(() => {
        routeStore.setLocation("Users")
    }, []);

    function changeRole(user, role) {
        usersStore.changeUserRole(user.id, role)
            .then(r => {
                swal({
                    title: "Success",
                    text: user.username + "`s role changed",
                    icon: "success"
                });
            })
            .catch(reason => {
                swal({
                    title: "Error",
                    text: reason.message,
                    icon: "error"
                });
            })
    }

    const [query, setQuery] = useState();
    const [sort, setSort] = useState({
        name: null,
        direction: false
    });

    const [pageable, setPageable] = useState(INITIAL_PAGE);

    useEffect(() => {
        usersStore.searchUsers(query, sort, pageable);
    }, [query, sort, pageable]);

    const columns = [
        {
            name: "ID",
            accessor: "id"
        },
        {
            name: "USERNAME",
            accessor: "username"
        },
        {
            name: "ROLE",
            accessor: "role"
        }
    ]

    return (
        <Fragment>
            <div className="page-titles d-flex justify-content-between">
                <div className="input-group input-primary">
                    <div className="input-group-prepend">
                    <span className="input-group-text p-0">
                        <i className="flaticon-381-search-2 scale3"/>
                    </span>
                    </div>
                    <input type="text" className="form-control bg-secondary-light mr-2" placeholder="Search here..."
                           style={{maxWidth: "300px"}}
                           onChange={(e) => {
                               setPageable(INITIAL_PAGE);
                               setQuery(e.target.value);
                           }}/>
                </div>
                <CreateUser/>
            </div>
            <ChangePasswordModal
                show={changePasswordModal}
                onHide={setChangePasswordModal}
                user={selectedUser}
            />

            <Table responsive>
                <thead>
                <tr role="row">
                    {columns.map(column =>
                        <th key={column.name} className="cursor-pointer text-nowrap"
                            onClick={() => setSort({name: column.accessor, direction: !sort.direction})}>
                            {column.name}
                            <span className="ml-1">
                                 {sort.name === column.accessor ? (sort.direction ? <i className="fa fa-arrow-down"/> :
                                     <i className="fa fa-arrow-up"/>) : ''}
							</span>
                        </th>
                    )}
                    <th className="d-none d-md-block">ACTION</th>
                </tr>
                </thead>
                <tbody>
                {usersStore.users.map((user, i) => (
                    <tr key={user.id}>
                        {columns.map(column => <td>{user[column.accessor]}</td>)}
                        <td>
                            <Dropdown className="dropdown text-sans-serif">
                                <Dropdown.Toggle
                                    variant="primary"
                                    className="light sharp i-false"
                                >
                                    {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    className="dropdown-menu dropdown-menu-right border py-0"
                                    aria-labelledby="order-dropdown-0"
                                >
                                    <div className="py-2">
                                        <span className="dropdown-item cursor-pointer text-success" onClick={() => {
                                            usersStore.loginAsUser(user.id).then(() => {});
                                        }}>
                                            <i className="fa fa-sign-in mr-2"/>Login As User
                                        </span>

                                        <span className="dropdown-item cursor-pointer" onClick={() =>
                                            openChangePasswordModal(user.toJSON())
                                        }>
                                            <i className="fa fa-lock mr-2"/>Change Password
                                        </span>

                                        {user.role === "Grower"
                                            ?
                                            <span className="dropdown-item cursor-pointer" onClick={() => {
                                                changeRole(user, "Super Admin");
                                            }}>
                                            <i className="fa fa-angle-double-up mr-2"/>Make Admin
                                            </span>
                                            :
                                            <span className="dropdown-item cursor-pointer" onClick={() => {
                                                changeRole(user, "Grower");
                                            }}>
                                            <i className="fa fa-angle-double-down mr-2"/>Make User
                                            </span>
                                        }

                                        <div className="dropdown-divider"/>
                                        <span className="dropdown-item cursor-pointer text-muted disabled"><i
                                            className="fa fa-remove mr-2"/>Delete</span>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 dataTables_wrapper">
                <div className="dataTables_info">
                    Showing <select value={pageable.size} className="p-0" style={{width: "fit-content"}}
                                    onChange={(e) => {
                                        setPageable({
                                            page: usersStore.page,
                                            size: e.target.value
                                        })
                                    }}>
                    <option>5</option>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                </select> per page of {usersStore.totalElements} users
                </div>
                <div className="dataTables_paginate paging_simple_numbers">

                    <Button
                        variant="dark"
                        size="sm"
                        className={`cursor-pointer ${
                            usersStore.first ? "active bg-secondary" : ""
                        } `}
                        onClick={() =>
                            setPageable({
                                page: 0,
                                size: usersStore.size
                            })}
                    >
                        1
                    </Button>

                    <Button
                        hidden={usersStore.first || usersStore.page === 1}
                        variant="dark"
                        disabled={usersStore.first}
                        className="ml-3 previous"
                        size="sm"
                        onClick={() =>
                            setPageable({
                                page: usersStore.page - 1,
                                size: usersStore.size
                            })
                        }
                    >
                        {usersStore.page}
                    </Button>

                    {!usersStore.first && !usersStore.last && <Button
                        variant="dark"
                        size="sm"
                        className={`ml-2 cursor-pointer active bg-secondary`}
                    >
                    {usersStore.page + 1}
                    </Button>}

                    <Button
                        hidden={usersStore.last || (usersStore.page === usersStore.totalPages - 2)}
                        variant="dark"
                        size="sm"
                        className="ml-2 mr-1 next cursor-pointer"
                        onClick={() =>
                            setPageable({
                                page: usersStore.page + 1,
                                size: usersStore.size
                            })
                        }
                    >
                        {usersStore.page + 2}
                    </Button>

                    <Button
                        hidden={usersStore.first && usersStore.last}
                        variant="dark"
                        size="sm"
                        className={`ml-2 cursor-pointer ${
                            usersStore.last ? "active bg-secondary" : ""
                        } `}
                        onClick={() =>
                            setPageable({
                                page: usersStore.totalPages - 1,
                                size: usersStore.size
                            })}
                    >
                        {usersStore.totalPages}
                    </Button>

                </div>
            </div>
        </Fragment>
    )
})
