import {
    CartesianGrid,
    Line,
    LineChart,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {profileStore} from "../../../store/ProfileStore";

export default function ReportChart({initialData, min, avg, max, phases, begin, end, cycle, parameter}) {

    const [data, setData] = useState(initialData);
    const [left, setLeft] = useState(begin);
    const [right, setRight] = useState(end + 3_600_000);

    const [top, setTop] = useState("dataMax+1");
    const [bottom, setBottom] = useState("dataMin");

    const [refAreaLeft, setRefAreaLeft] = useState();
    const [refAreaRight, setRefAreaRight] = useState();

    const [mousePressed, setMousePressed] = useState(false);

    useEffect(() => {
        window.onmouseup = ev => {
            setMousePressed(false);
        }
        window.onmousedown = ev => {
            setMousePressed(true)
        }
    }, [])

    useEffect(() => {
        setData(initialData);
        setLeft(begin);
        setRight(end + 3_600_000);
    },[initialData, begin, end])

    useEffect(() => {
        if (!mousePressed) {
            zoom();
        }
    }, [mousePressed])


    const getAxisYDomain = (from, to, ref, offset) => {
        const refData = initialData.filter(cdata => +cdata.time > from && +cdata.time < to);
        let [bottom, top] = [refData[0][ref], refData[0][ref]];
        refData.forEach((d) => {
            if (d[ref] > top) top = d[ref];
            if (d[ref] < bottom) bottom = d[ref];
        });

        return [(bottom | 0) - offset, (top | 0) + offset];
    };

    function zoom() {
        if (refAreaLeft === refAreaRight || refAreaRight === "") {
            setRefAreaLeft(null);
            setRefAreaRight(null);
            return;
        }
        let refLeft = refAreaLeft;
        let refRight = refAreaRight;

        // xAxis domain
        if (refLeft > refRight)
            [refLeft, refRight] = [refRight, refLeft];

        const [b, t] = getAxisYDomain(refLeft, refRight, 'value', 1);

        setTop(t);
        setBottom(b);

        setRefAreaLeft(null);
        setRefAreaRight(null);

        setData(data.slice())

        setLeft(+refLeft);
        setRight(+refRight);
    }

    function zoomOut() {
        setData(data.slice());
        setRefAreaLeft("");
        setRefAreaRight("");

        setTop("dataMax+1");
        setBottom("dataMin");

        setLeft(begin);
        setRight(end + 3_600_000);
    }

    return <Card>
        <Card.Header>
            <Card.Title className={"text-white"}>{`${cycle} (${parameter})`}</Card.Title>
            <Card.Subtitle>

                <Button
                    type="button"
                    variant={"outline-primary"}
                    size={"sm"}
                    className="btn btn-square btn-sm text-nowrap"
                    onClick={() => zoomOut()}
                >
                    Zoom Out
                </Button>
            </Card.Subtitle>
        </Card.Header>

        <Card.Body className={"pb-0"}>
            <ResponsiveContainer height={540}>
                <LineChart data={data}
                           margin={{top: 20, right: 0, bottom: 0, left: 0}}
                           onMouseDown={(e) => {
                               setRefAreaLeft(e?.activeLabel)
                           }}
                           onMouseMove={(e) => {
                               refAreaLeft && setRefAreaRight(e?.activeLabel);
                           }}
                           // onMouseUp={() => zoom()}
                >

                    {min.map(line =>
                        <ReferenceLine key={`rl-min-${line[0].x}`}
                                       ifOverflow={"visible"}
                                       yAxisId="left"
                                       stroke="#5e72e4"
                                       strokeDasharray="3 3"
                                       segment={line}
                        />
                    )}

                    {avg.map(line =>
                        <ReferenceLine key={`rl-avg-${line[0].x}`}
                                       ifOverflow={"visible"}
                                       yAxisId="left"
                                       stroke="#FFAC30"
                                       strokeDasharray="3 3"
                                       segment={line}/>
                    )}

                    {max.map(line =>
                        <ReferenceLine key={`rl-max-${line[0].x}`}
                                       ifOverflow={"visible"}
                                       yAxisId="left"
                                       stroke="#EE3232"
                                       strokeDasharray="3 3"
                                       segment={line}/>
                    )}

                    {phases.map(phase =>
                        <ReferenceLine
                            key={`phase-${phase.time}`}
                            x={phase.time}
                            label={{value: phase.name, position: "top", fill: 'white', style: {textAnchor: "start"}}}
                            yAxisId="left"
                            stroke="#864ad1" />
                    )}

                    <Line yAxisId="left" type="stepBefore" dataKey="value" stroke="#43DC80" strokeWidth={1} dot={false}/>

                    <CartesianGrid stroke="#505060" strokeDasharray="2 2"/>

                    <XAxis
                        allowDataOverflow
                        dataKey="time"
                        tick={{stroke: "white", fontSize: 12}}
                        name={"Time"}
                        type={"number"}
                        domain={[left, right]}
                        tickCount={20}
                        tickFormatter={timeStr => moment(timeStr).format('DD MMM HH:mm Z')}/>

                    <YAxis allowDataOverflow
                           yAxisId="left"
                           domain={[bottom, top]}
                           type="number"
                           tick={{stroke: "white", fontSize: 12}}/>

                    <Tooltip
                        labelFormatter={t => {
                            return <div>
                                <span>{moment(t/1).format('DD MMM HH:mm')}</span><br/>
                                <span>{profileStore.format(t/1, "DD MMM HH:mm z")}</span>
                            </div>
                        }}
                        formatter={(v,n,p) => [v, parameter]}
                    />

                    {mousePressed && refAreaLeft && refAreaRight ? (
                        <ReferenceArea
                            yAxisId="left"
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    ) : null}

                </LineChart>
            </ResponsiveContainer>
        </Card.Body>
        <Card.Footer className={"border-0 py-1"}>
            <Row>
                <Col xs={4} className={"text-red"}>--- max</Col>
                <Col xs={4} className={"text-warning"}>--- avg</Col>
                <Col xs={4} className={"text-blue"}>--- min</Col>
            </Row>
        </Card.Footer>

    </Card>
}
