import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {rootStore} from "../../../store/RootStore";

export function AddRoomToProjectModal({project, show, setShow}) {

    const handleClose = () => setShow(false);
    const [rooms, setRooms] = useState([]);
    const [error, setError] = useState();

    const [options, setOptions] = useState([])

    useEffect(() => {
        const projectsRooms = rootStore.projects.flatMap(p => p?.rooms).map(r=>r.id);

        const opts = rootStore.rooms?.filter(r => !projectsRooms.includes(r.id)).map(room => {
            return {
                key: room.id,
                label: room.name
            }
        });
        setOptions(opts);
    }, [])


    function addRoom() {
        project.addRoomsToProject(rooms)
        handleClose()
    }

    return <Modal className="modal fade" show={show} onHide={setShow}>

        <Modal.Header closeButton>
            <Modal.Title>Add Growing Areas to Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onKeyPress={event => {
                if (event.code === "Enter") {
                    event.preventDefault();
                    addRoom();
                }
            }}>
                <Form.Group>
                    <Form.Label className="text-black font-w500">Choose growing area(s) for Project:</Form.Label>
                    <DropdownMultiselect
                        className="text-black font-w500"
                        buttonClass="btn-dark"
                        options={options}
                        handleOnChange={(selected) => {
                            setRooms(selected)
                        }}
                    />
                </Form.Group>
            </Form>

            {error && (
                <Alert
                    variant="danger"
                    className="solid alert-dismissible fade show"
                >
                    <strong>Error.</strong> {error}
                </Alert>
            )}
        </Modal.Body>

        <Modal.Footer>
            <Button
                block
                variant="secondary"
                onClick={addRoom}>
                ADD
            </Button>
        </Modal.Footer>

    </Modal>;
}
