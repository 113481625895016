import {Link} from "react-router-dom";
import React from "react";

export function ComingSoon({title}) {
    return <div className="h-100">
        <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
                <div className="col">
                    <div className="form-input-content text-center error-page">
                        <h3 className="error-text font-weight-bold">{title}</h3>
                        <h4>
                            <i className="fa fa-clock-o text-secondary"/> Cooming Soon
                        </h4>
                        <p>Please keep calm</p>
                        <div>
                            <Link className="btn btn-primary" to="/">
                                Back to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
