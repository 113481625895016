import {Button} from "react-bootstrap";
import React from "react";

export const ModeButton = ({text, active, disabled, started, onClick}) => {
    const style = getStyle(active, disabled, started);

    return <Button variant={"secondary"} className={"mr-1"} onClick={onClick} disabled={disabled}>
        <i className={`fa ${style} mr-2`}/>
        {text}
    </Button>
}

function getStyle(active, disabled, started) {
    if (active && !disabled && started) return "fa-circle text-primary-green";
    if (active && !disabled && !started) return "fa-circle text-danger";
    return "fa-circle text-muted";
}
