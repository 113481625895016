import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, Col, FormControl, Row, Table} from "react-bootstrap";
import {parameterIcon, parameterTitle} from "../../utils/ParameterHelper";
import Nouislider from "nouislider-react";
import {observer} from "mobx-react-lite";
import {loaderStore} from "../../../store/LoaderStore";

const Lights = ({name, value, setValue}) => {
    const className = name.toLowerCase().replace(' ', '-');

    return <div key={`${className}`}>
        <div className={"d-flex justify-content-between mb-1"}>
            <p className={`text-${className} m-0`}>{name}</p>
            <FormControl className={`p-0 text-${className} text-right`}
                         plaintext type="number"
                         size={"sm"}
                         min={0} max={100}
                         value={value}
                         style={{width: `3em`}}
                         onChange={e => {
                             // console.log(e.target.value)
                             setValue(+e.target.value);
                         }}/>
        </div>

        <div className={"pb-4"}>
            <Nouislider
                key={className}
                id={className}
                connect={[true, false]}
                start={value}
                pips={{mode: "steps", density: 5, filter: () => 2}}
                clickablePips
                behaviour="tap"
                range={{min: 0, max: 100}}
                animate={false}
                onUpdate={values => {
                    setValue(~~values);
                }}
            />
        </div>
    </div>
};

const ManualModePanel = ({parameters, item, cycleMode, onApply}) => {

    const {ch1, ch2, ch3, ch4} = item || {};

    const [blue, setBlue] = useState(ch1 || 0);
    const [red, setRed] = useState(ch2 || 0);
    const [deepBlue, setDeepBlue] = useState(ch3 || 0);
    const [farRed, setFarRed] = useState(ch4 || 0);

    const [params, setParams] = useState(parameters?.map(p=>({name: p.name, min: p.min, max: p.max})) || []);
    const [cancel, setCancel] = useState(false);

    const [changed, setChanged] = useState(false);

    useEffect(() => {
        const {ch1, ch2, ch3, ch4} = item || {};
        setBlue(ch1 || 0);
        setRed(ch2 || 0);
        setDeepBlue(ch3 || 0);
        setFarRed(ch4 || 0);
    }, [item, cancel])

    useEffect(() => {
        setParams(parameters?.map(p=>({name: p.name, min: p.min, max: p.max})) || []);
    }, [parameters, cancel])

    useEffect(() => {
        if (!item || !parameters) return;
        const {ch1, ch2, ch3, ch4} = item;
        const noChange = blue === ch1 && red === ch2 && deepBlue === ch3 && farRed === ch4
            && JSON.stringify(parameters?.map(p=>({name: p.name, min: p.min, max: p.max})))===JSON.stringify(params)

        setChanged(!noChange);
    }, [blue, red, deepBlue, farRed, params, parameters, item])

    return <Col>
        <Card className={"col"}>
            <Card.Body>
                <Row>
                    <Col lg={6}>
                        <Lights name={"Blue"} value={blue} setValue={setBlue}/>
                        <Lights name={"Red"} value={red} setValue={setRed}/>
                        <Lights name={"Deep Blue"} value={deepBlue} setValue={setDeepBlue}/>
                        <Lights name={"Far Red"} value={farRed} setValue={setFarRed}/>
                    </Col>

                    <Col lg={6} className={"d-flex flex-column justify-content-between"}>
                        <div>
                            <h5>Parameters</h5>
                            <Table size={"sm"}>
                                <thead>
                                <tr>
                                    <th className={"p-0"}/>
                                    <th className={"p-0"}>min</th>
                                    <th className={"p-0"}>max</th>
                                </tr>
                                </thead>
                                <tbody>

                                {params?.map((parameter, i) => {
                                    return <tr key={`phase-${i}-parameter-${parameter.name}`}>
                                        <td className={"p-0 py-1"}>
                                            <div className="micro-ic">
                                                {parameterIcon(parameter.name)}
                                                <span
                                                    className={"text-primary ml-2"}>{parameterTitle(parameter.name)}</span>
                                            </div>
                                        </td>
                                        <td className={"p-0"}>
                                            <FormControl className={"p-0 text-white"} plaintext type="number"
                                                         size={"sm"}
                                                // readOnly={readOnly}
                                                         min={0} max={9999}
                                                         value={parameter.min}
                                                         maxLength={4}
                                                         style={{width: `4em`}}
                                                         onChange={e => {
                                                             setParams(prev => {
                                                                 const state = [...prev];
                                                                 state[i]["min"] = parseInt(e.target.value);
                                                                 return state;
                                                             })
                                                         }}/>
                                        </td>
                                        <td className={"p-0"}>
                                            <FormControl className={"p-0 text-white"} plaintext type="number"
                                                         size={"sm"}
                                                // readOnly={readOnly}
                                                         min={0} max={9999}
                                                         value={parameter.max}
                                                         maxLength={4}
                                                         style={{width: `4em`}}
                                                         onChange={e => {
                                                             setParams(prev => {
                                                                 const state = [...prev];
                                                                 state[i]["max"] = e.target.value;
                                                                 return state;
                                                             })
                                                         }}/>
                                        </td>
                                    </tr>
                                })}

                                </tbody>
                            </Table>
                        </div>

                        <div className={"d-flex justify-content-end"}>
                            <ButtonGroup size={"sm"}>
                                <Button variant={"danger"} hidden={!changed} onClick={() => {
                                    setCancel(!cancel);
                                }}>Cancel</Button>
                                <Button disabled={loaderStore?.load} hidden={!changed} onClick={
                                    () => onApply(
                                        {ch1: blue, ch2: red, ch3: deepBlue, ch4: farRed},
                                        params,
                                        setChanged(false)
                                    )
                                }>Apply</Button>
                            </ButtonGroup>
                        </div>

                    </Col>

                </Row>

            </Card.Body>
        </Card>
    </Col>
}

export default observer(ManualModePanel);
