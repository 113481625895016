import React, {useState} from "react";
import {Card, Col, Dropdown, Table} from "react-bootstrap";
import {computeLightHours, computeNightHours, computeProtocolDurationDays} from "../../utils/ItemHelper";
import swal from "sweetalert";

export function ProtocolComponent({protocol, deleteProtocol, copyProgram}) {

    function getPhaseColorClass(phaseName) {
        if (phaseName === "Vega") return "text-info"
        if (phaseName === "Flowering") return "text-danger"
        return "";
    }

    const CustomDropdown = ({protocol}) => {
        const [open, setOpen] = useState(false);

        return <Dropdown className="dropdown text-sans-serif" onToggle={() => setOpen(!open)} show={open}>
            <Dropdown.Toggle
                variant=""
                className="close i-false ml-2"
                type="button"
                id="order-dropdown-0"
                data-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
                style={{verticalAlign: "baseline", float: "inherit"}}
                onClick={e => {
                    e.preventDefault();
                    setOpen(true);
                }}
            >
                <span>
                  <i className="fa fa-bars"/>
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right border py-0"
                aria-labelledby="order-dropdown-0"
            >
                <div className="py-2">
                    <Dropdown.Item eventKey="1" className={"text-white"} onClick={() => copyProgram(protocol.id)}>
                        <i className="fa fa-clone mr-2"/> Copy Program
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item eventKey="2" className={"text-danger"} onClick={() => {
                        swal({
                            title: "Are you sure?",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                deleteProtocol(protocol.id)
                                swal("Protocol has been removed!", {
                                    icon: "success",
                                });
                            }
                        })
                    }}>
                        <i className="fa fa-trash mr-2"/> Delete Program
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    }

    return <Card key={protocol.id}>

        <Card.Body className="project-info">

            <Col xl={3} lg={6} sm={12} className={"my-2"}>
                {!!deleteProtocol && !protocol.shared &&
                    <p className={"text-primary mb-2 d-flex"}>#P-{protocol.id} <CustomDropdown protocol={protocol}/></p>
                }
                <h4 className={"title font-w600 mb-3"}>{protocol.name} {protocol.shared && !!copyProgram && <span className={"text-secondary ml-2 cursor-pointer"} onClick={(e) => {
                    e.preventDefault();
                    copyProgram(protocol.id);
                }}><i className="fa fa-clone"/> </span>}</h4>

                <div className={"text-dark"}>
                    Growing Period: {computeProtocolDurationDays(protocol)} days
                </div>
            </Col>

            <Col xl={3} lg={6} sm={12} className={"my-2"}>
                <div className={"text-dark"}>{protocol.description}</div>
            </Col>
            {/*auto clip hidden inherit initial overlay revert scroll unset visible*/}
            <Col xl={6} lg={12} className={"d-flex justify-content-xl-end"}>
                <div className={"table-responsive"} style={{width: "fit-content"}}>
                    <Table size={"sm"} className={"mb-0 p-0"} style={{width: "fit-content", overflowY: "clip"}}>
                        <tbody>

                        <tr className={"text-monospace"}>
                            <td className={"p-0 px-2 border-0"}>&nbsp;</td>
                            {protocol.phases.map(phase =>
                                <td className={`p-0 border-0 px-2 ${getPhaseColorClass(phase.phaseName)}`}>{phase.name}</td>)}
                        </tr>

                        <tr className={"text-white text-monospace"}>
                            <td className={"p-0 px-2"}><span className="mdi mdi-loop"/></td>
                            {protocol.phases.map(phase => <td className={"p-0 px-2"}>{phase.loops}</td>)}
                        </tr>

                        <tr className={"text-warning text-monospace"}>
                            <td className={"p-0 px-2"}><span className="mdi mdi-white-balance-sunny"/></td>
                            {protocol.phases.map(phase => <td className={"p-0 px-2"}>{computeLightHours(phase.items)}</td>)}
                        </tr>

                        <tr className={"text-black-100 border-bottom-0"}>
                            <td className={"p-0 px-2"}><span className="mdi mdi-weather-night"/></td>
                            {protocol.phases.map(phase => <td className={"p-0 px-2"}>{computeNightHours(phase.items)}</td>)}
                        </tr>

                        </tbody>
                    </Table>
                </div>
            </Col>
        </Card.Body>

    </Card>
}
