export const newProtocolObject = () => ({
    name: "",
    description: "",
    phaseName: "",
    culture: {
        family: "",
        genus: "",
        species: "",
        variety: "",
        genetics: "",
        growing: "",
        flowering: "",
        yield: ""
    },
    cultivation: {
        cultivation: "",
        substrat: "",
        volume: "",
        irrigation: ""
    },
    phases: []
})

export const newPhaseObject = () => ({
    name: "",
    // description: "",
    loops: 1,
    parameters: [
        {name: "CO2", min: 0, max: 0},
        {name: "T", min: 0, max: 0},
        {name: "RH", min: 0, max: 0},
        {name: "P", min: 0, max: 0}
    ],
    items: [
        {ch1: 0, ch2: 0, ch3: 0, ch4: 0, period: 0},
    ],
})

export const newItemObject = () => ({ch1: 0, ch2: 0, ch3: 0, ch4: 0, period: 0})
