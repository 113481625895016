import moment from "moment";

export function computeDurationHours(items) {
    return ~~moment.duration(items.map(i => i.period).reduce((sum, period) => sum + period), "minutes").asHours();
}

export function computeNightHours(items) {
    return ~~moment.duration(
        items
            .filter(i => i.ch1 + i.ch2 + i.ch3 + i.ch4 === 0)
            .map(i => i.period)
            .reduce((sum, period) => sum + period), "minutes")
        .asHours();
}

export function computeLightHours(items) {
    return ~~(computeDurationHours(items) - computeNightHours(items));
}

export function computeProtocolDurationDays(protocol) {
    const dur = protocol.phases.map(phase => computeDurationHours(phase.items) * phase.loops)
        .reduce((p, c) => p + c, 0);

    return  ~~moment.duration(dur, "hours").asDays();
}
