import {io} from "socket.io-client";
import {TOKEN} from "./api";
import {socketStore} from "../store/SocketStore";

const SOCKET_SERVER_URL = process.env.REACT_APP_IO_HOST;
export const socket = io(SOCKET_SERVER_URL, {
    query: {"token": localStorage.getItem(TOKEN), "frontend": true}
});
socket.on("connect", () => socketStore.connect());
socket.on("disconnect", () => socketStore.disconnect());
export const subscribe = (room) => socket.emit("subscribe", room);
export const unsubscribe = (room) => socket.emit("unsubscribe", room);
