import React, {useEffect, useState} from "react";
import {Alert, Modal} from "react-bootstrap";
import * as PropTypes from "prop-types";
import swal from "sweetalert";
import {usersStore} from "../../../store/UsersStore";
import {loaderStore} from "../../../store/LoaderStore";

ChangePasswordModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    user: PropTypes.object
};

export function ChangePasswordModal({show, onHide, user}) {

    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setError(null);
    }, [show, password, confirm]);

    return <Modal className="modal fade" show={show} onHide={onHide}>
        <div className="" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Change Password for <b>{user?.username}</b></h5>
                    <button type="button" className="close" onClick={() => onHide(false)}>
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>

                        <div className="form-group">
                            <label className="text-black font-w500">Password</label>
                            <input type="password" className="form-control"
                                   onChange={(e) => setPassword(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label className="text-black font-w500">Confirm Password</label>
                            <input id="user_create_confirm" type="password" className="form-control"
                                   onChange={(e) => setConfirm(e.target.value)}/>
                        </div>

                        {error && (
                            <Alert
                                variant="danger"
                                className="solid alert-dismissible fade show"
                            >
                                <strong>Error.</strong> {error}
                            </Alert>
                        )}

                        <div className="form-group">
                            <button type="button" className="btn btn-block btn-secondary" disabled={loaderStore.load}
                                    onClick={() => {

                                        if (password !== confirm) {
                                            setError("Password does not match");
                                            return;
                                        }

                                        usersStore.changeUserPassword(user.id, password, confirm)
                                            .then(value => {
                                                onHide(false);
                                                swal({
                                                    title: "Success",
                                                    text: user.username + "`s password changed",
                                                    icon: "success"
                                                });
                                            })
                                            .catch(reason => setError(reason.message))

                                    }}>
                                Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Modal>;
}

