import moment from "moment-timezone";
import {api} from "../api/restConfig";

const {types} = require("mobx-state-tree");

const ProfileStore = types.model("ProfileStore", {
    f: types.optional(types.boolean, false),
    tz: types.optional(types.string, "Europe/Kiev"),
}).views((profile) => ({
    datetime(date) {
        return moment.utc(date).tz(this.tz).format("lll");
    },
    format(date, format) {
        return moment.utc(date).tz(this.tz).format(format);
    },
    calendar(date) {
        return moment.utc(date).tz(this.tz).calendar();
    },
    date(date) {
        return moment.utc(date).tz(this.tz).format("ll");
    },
    get tempUnit() {
        return this.f ? "Fahrenheit" : "Celsius";
    }
})).actions((profile) => ({
    afterCreate() {
        api.get("user/info").then(res => {
            let info = res.data;
            if (!!info.f) this.setF(info.f);
            if (!!info.tz) this.setTz(info.tz);
            return info;
        })
    },
    setF(f) {
        profile.f = f;
    },
    setTz(tz) {
        profile.tz = tz;
    },
    async changeF(f) {
        await api.post("user/info", {f: f}).then(res => this.setF(f));
    },
    async changeTz(tz) {
        await api.post("user/info", {tz: tz}).then(res => this.setTz(tz));
    }
}));

export const profileStore = ProfileStore.create({})
