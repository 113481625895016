import React, {useEffect} from 'react';
import {routeStore} from "../../store/RouteStore";
import {authStore} from "../../store/AuthStore";
import {Card, Col, Form, Media} from "react-bootstrap";
import moment from "moment-timezone";
import {profileStore} from "../../store/ProfileStore";
import swal from "sweetalert";
import {observer} from "mobx-react-lite";

export default observer(function Profile() {

    useEffect(() => {
        routeStore.setLocation("Profile")
    }, [])

    return(
        <div className="row">
            <div className="col-lg-12">
                <div className="profile card card-body px-3 pt-3 pb-0">
                    <div className="profile-info">
                        <div className="profile-photo">
                            <img
                                src={authStore.avatarUrl}
                                className="img-fluid rounded-circle"
                                alt="profile"
                            />
                        </div>
                        <div className="profile-details">
                            <div className="profile-name px-3 pt-2">
                                <h4 className="text-primary mb-0">{authStore.username}</h4>
                                <p>{authStore.role}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Col lg={6} xl={4}>
                <Card className={"widget-stat"}>
                    <Card.Body>
                        <Media className={"ai-icon"}>
                            <span className="mr-3 text-primary" style={{background: ""}}>
                                <i className={"fa fa-thermometer-half scale5 text-danger"}/>
                            </span>
                            <div className="media-body">
                                <p className="text-danger mb-2">Temperature Unit</p>
                                <Form.Group>
                                    <Form.Control as="select" size={"lg"} className={"text-danger"} onChange={(e) => {
                                        profileStore.changeF(e.target.value === "F").then().catch(reason => {
                                            swal({
                                                title: "Error",
                                                text: reason?.message,
                                                icon: "error"
                                            })
                                        })
                                    }}>
                                        <option selected={!profileStore.f} label={"°C"}>C</option>
                                        <option selected={profileStore.f} label={"°F"}>F</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </Media>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={6} xl={4}>
                <Card className={"widget-stat"}>
                    <Card.Body>
                        <Media className={"ai-icon"}>
                            <span className="mr-3 bgl-primary text-primary">
                                <i className={"las la-clock scale5 text-info"}/>
                            </span>
                            <div className="media-body">
                                <p className="mb-2 text-info">Timezone</p>
                                <Form.Group>
                                    <Form.Control as="select" size={"lg"} className={"text-info"} onChange={(e) => {
                                        profileStore.changeTz(e.target.value).then().catch(reason => {
                                            swal({
                                                title: "Error",
                                                text: reason?.message,
                                                icon: "error"
                                            })
                                        })
                                    }}>
                                        {moment.tz.names().map(tz=><option selected={profileStore.tz===tz}>{tz}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </Media>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
});
