import {Card, Media} from "react-bootstrap";
import React from "react";

export function ConsumedElectricity({cycle}) {
    return <Card className={"widget-stat"} style={{backgroundColor: "#2f363e"}}>
        <Card.Body className={"p-4"}>
            <Media>
                <div className="power-ic">
                    <i className="fa fa-bolt mr-3" aria-hidden="true"/>
                </div>
                <Media.Body className={"text-white"}>
                    <p className="mb-1">Consumed Electricity</p>
                    <h4 className="text-white">{!!cycle?.kwh ? cycle.kwh : "-"} kWh</h4>
                </Media.Body>
            </Media>
        </Card.Body>
    </Card>;
}
