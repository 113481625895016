import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";

export function EditRoom({show, setShow, room}) {

    const handleClose = () => setShow(false);

    const [name, setName] = useState(room?.name);
    const [description, setDescription] = useState(room?.description);

    const [error, setError] = useState();

    function editRoom() {
        room.edit({
            name: name,
            description: description
        }).then(__ => handleClose())
            .catch(reason => setError(reason.message))
    }

    return (
        <div>
            <Modal className="modal fade" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Growing Area</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyPress={event => {
                        if (event.code === "Enter") {
                            event.preventDefault();
                            editRoom();
                        }
                    }}>
                        <Form.Group>
                            <Form.Label className="text-black font-w500">Growing Area Name</Form.Label>
                            <Form.Control maxlength="50" aria-describedby="nameHelp"
                                          defaultValue={room?.name}
                                          onChange={(e) => {
                                              setName(e.target.value);
                                          }}
                            />
                            <Form.Text id="nameHelp" muted>
                                Growing Area name must be less than 50 characters long, and
                                must not contain special characters, or emoji.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-black font-w500">Growing Area Description</Form.Label>
                            <Form.Control as="textarea" maxlength="200" rows={2} aria-describedby="descriptionHelp"
                                          defaultValue={room?.description}
                                          onChange={(e) => {
                                              setDescription(e.target.value);
                                          }}
                            />
                            <Form.Text id="descriptionHelp" muted>
                                Growing Area description must be less than 200 characters long.
                            </Form.Text>

                        </Form.Group>
                    </Form>
                    {error && (
                        <Alert
                            variant="danger"
                            className="solid alert-dismissible fade show"
                        >
                            <strong>Error.</strong> {error}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block variant="secondary" onClick={() => {
                        editRoom();
                    }}>
                        Update Growing Area
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
