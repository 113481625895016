import {types} from "mobx-state-tree"
import {login, TOKEN} from "../api/api";

const AuthStore = types.model("AuthStore",
    {
        auth: types.boolean,
        username: types.string,
        admin: types.boolean,
        dialog: types.maybeNull(types.boolean)
    })
    .views((store) => ({
        get role() {
            return this.admin ? "Super Admin" : "Grower";
        },
        get avatarUrl() {
            return "https://eu.ui-avatars.com/api/?background=864AD1&bold=true&name=" + this.username
        }
    }))
    .actions((store) => ({
        setAuth(token) {
            const details = parseAuth(token);
            store.auth = details.auth;
            store.admin = details.admin;
            store.username = details.username;
        },
        clearAuth() {
            store.auth = false;
            store.admin = false;
            store.username = "";
        },
        setDialog(dialog) {
            store.dialog = dialog;
        },
        async login(username, password, confirm=false) {
            const response = await login(username, password, confirm);
            if (response.status === 200) {
                await localStorage.setItem(TOKEN, response.data.token);
                this.setAuth(response.data.token);
            }
            if (response.status === 202) {
                this.setDialog(true);
            }
        },
        async logout() {
            localStorage.removeItem(TOKEN);
            this.clearAuth();
        }
    }))

function parseAuth(token) {
    if (!token) return {auth: false, admin: false, username: ""};

    try {
        let jwt = parseJwt(token);
        return {
            auth: true,
            admin: jwt.a,
            username: jwt.sub
        };
    } catch (e) {
        return {auth: false, admin: false, username: ""};
    }
}

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const authStore = AuthStore.create({
    ...parseAuth(localStorage.getItem(TOKEN))
})
