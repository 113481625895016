import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {socket, subscribe, unsubscribe} from "../../../api/socket";
import {observer} from "mobx-react-lite";
import {socketStore} from "../../../store/SocketStore";

export default observer(function RemotePanel({remotes: remoteList}) {

    const [remotes, setRemotes] = useState(remoteList);

    useEffect(() => {
        setRemotes(remoteList);
    }, [remoteList]);

    const ss = socketStore;

    useEffect(() => {
        if (ss.connected) {
            for (const remote of remotes) {
                const room = `remote.${remote?.remoteId}.status`;

                function callback(e) {
                    const find = remotes.find(r => r.remoteId === remote.remoteId);
                    if (!!find) find.setOnline(e.online);
                }

                socket.on(room, callback);
                subscribe(room);
            }
        }

        if (!!remotes && remotes.length > 0)
            return () => {
                for (const remote of remotes) {
                    const room = `remote.${remote.remoteId}.status`;

                    unsubscribe(room);
                    socket.off(room);
                }
            };
    }, [ss.connected, remotes])

    return <Card>
        <Card.Body className={"widget-media p-4"}>

            <ul className={"timeline"}>
                {
                    remotes?.map(remote => {
                        const color = remote.online !== undefined ? remote.online ? "success" : "danger" : "gray";
                        const status = remote.online !== undefined ? remote.online ? "ONLINE" : "OFFLINE" : "UNKNOWN";

                        return <li key={remote?.remoteId}>
                            <div className="timeline-panel">
                                <div className={`media mr-2 media-${color}`}><i className={`mdi mdi-server`}/></div>
                                <div className="media-body">
                                    <h5 className="mb-1">
                                        <span className={`text-primary`}>{remote?.name}</span>
                                    </h5>
                                    <h6 className="mb-1"><small className={`text-${color}`}>{status}</small></h6>
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>
        </Card.Body>
    </Card>;
});
