import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {rootStore} from "../../../store/RootStore";

export function CreateRoom() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [error, setError] = useState();

    function createRoom() {
        rootStore.createRoom({
            id: 5,
            name: name,
            description: description
        })
            .then(__ => handleClose())
            .catch(reason => setError(reason.message));
    }

    return (
        <div>
            <Button variant="secondary" className="rounded mr-3 mb-sm-0 mb-2 text-white" onClick={handleShow}>
                <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create New Growing Area
            </Button>
            <Modal className="modal fade" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Growing Area</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyPress={event => {
                        if (event.code === "Enter") {
                            event.preventDefault();
                            createRoom();
                        }
                    }}>
                        <Form.Group>
                            <Form.Label className="text-black font-w500">Growing Area Name</Form.Label>
                            <Form.Control maxlength="50" aria-describedby="nameHelp"
                                          onChange={e => {
                                              setName(e.target.value);
                                          }}/>
                            <Form.Text id="nameHelp" muted>
                                Growing Area name must be less than 50 characters long, and
                                must not contain special characters, or emoji.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="text-black font-w500">Growing Area Description</Form.Label>
                            <Form.Control as="textarea" maxlength="200" rows={2}
                                          aria-describedby="descriptionHelp"
                                          onChange={e => {
                                              setDescription(e.target.value);
                                          }}/>
                            <Form.Text id="descriptionHelp" muted>
                                Growing Area description must be less than 200 characters long.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                    {error && (
                        <Alert
                            variant="danger"
                            className="solid alert-dismissible fade show"
                        >
                            <strong>Error.</strong> {error}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block variant="secondary" onClick={() => {
                        createRoom();
                    }}>
                        Create Growing Area
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>


    );
}
