import {api} from "../api/restConfig";
import makeInspectable from "mobx-devtools-mst";
import moment from "moment";
import {getParentOfType} from "mobx-state-tree";
import {profileStore} from "./ProfileStore";
import {computeDurationHours, computeNightHours} from "../jsx/utils/ItemHelper";
import swal from "sweetalert";

const {types, flow} = require("mobx-state-tree");

const Parameter = types.model("Parameter", {
    name: types.string,
    min: types.number,
    max: types.number
}).views(self => ({
    get icon() {
        switch (self.name) {
            case "CO2": return <i className="mdi mdi-periodic-table-co2 scale3 bg-warning" aria-hidden="true"/>;
            case "T": return <i className="mdi mdi-thermometer-lines scale3 bg-danger" aria-hidden="true"/>;
            case "RH": return <i className="mdi mdi-water-percent scale3 bg-info" aria-hidden="true"/>;
            case "P": return <i className="las la-compress-arrows-alt scale3 bg-secondary" aria-hidden="true"/>;
            default: return <i className="las la-question-circle" aria-hidden="true" />;
        }
    },
    get title() {
        switch (self.name) {
            case "CO2": return "Carbon Dioxide Level";
            case "T": return "Temperature" ;
            case "RH": return "Relative Humidity";
            case "P": return "Pressure";
            default: return "";
        }
    },
    get hint() {
        switch (self.name) {
            case "T": return profileStore.tempUnit;
            case "RH": return "Percent";
            case "P": return "mmHg";
            case "CO2": return "ppm";
            default: return undefined;
        }
    },
    get boundaries() {
        switch (self.name) {
            case "CO2": return [0, 2000];
            case "T": return [5, 40];
            case "RH": return [0, 100];
            case "P": return [700, 900];
            default: return [0, 100];
        }
    }
}))

export const Item = types.model("Item", {
    ch1: types.number,
    ch2: types.number,
    ch3: types.number,
    ch4: types.number,
    period: types.number
})

const Phase = types.model("Phase", {
    name: types.string,
    loops: types.number,
    items: types.array(Item),
    parameters: types.array(Parameter),
    status: types.maybeNull(types.string),
    minutesToStart: types.maybeNull(types.number),
    durationMinutes: types.maybeNull(types.number)
}).views(self => ({
    get daysToStart() {
        return ~~moment.duration(self.minutesToStart, "minutes").asDays();
    },
    get nightHours() {
        return computeNightHours(self?.items);
    },
    get dayHours() {
        return computeDurationHours(self?.items);

    },
    get lightHours() {
        return this.dayHours - this.nightHours;
    }
})).actions(self => {
    return {
        apply: flow(function* (cycleID, index, phase, items, parameters) {
            phase.submit();

            const req = {
                name: phase.name,
                loops: phase.loops,
                items: [...items.map(item => {
                    return {ch1: item.ch1, ch2: item.ch2, ch3: item.ch3, ch4: item.ch4, period: item.period};
                })],
                parameters: [...parameters.map(parameter => {
                    parameter.submit();
                    return parameter.toJSON();
                })]
            }

            const cycle = getParentOfType(phase, Cycle);
            return yield api.patch(`cycle/${cycleID}/phase/${index}`, {...req})
                .then(r => cycle.update(r.data));
        })
    }
});

const Protocol = types.model("Protocol", {
    phases: types.array(Phase)
})

const Manual = types.model("Manual", {
    item: types.maybeNull(Item),
    parameters: types.array(Parameter),
})

const Cycle = types.model("Cycle", {
    id: types.identifierNumber,
    name: types.string,
    mode: types.string,
    status: types.maybeNull(types.string),
    startedAt: types.maybeNull(types.string),
    harvestAt: types.maybeNull(types.string),
    minutesToHarvest: types.maybeNull(types.number),
    protocol: types.maybeNull(Protocol),
    roomName: types.string,
    projectName: types.maybeNull(types.string),
    kwh: types.maybeNull(types.number),
    manual: types.maybeNull(Manual),
    terminated: types.boolean
}).views((cycle) => ({
    get leftToHarvest() {
        if (!cycle?.startedAt || !cycle?.harvestAt) return "";
        return `(${moment.duration(cycle?.minutesToHarvest, "minutes").humanize(true)})`;
    },
    get power() {
        return "-";
    },
    get startAtString() {
        if (!cycle?.startedAt) return "-";
        return profileStore.datetime(cycle?.startedAt);
    },
    get harvestAtString() {
        if (!cycle?.harvestAt) return "-";
        return profileStore.date(cycle?.harvestAt);
    },
    get currentPhase() {
        return cycle?.protocol?.phases.find(phase => phase.status === "CURRENT");
    },
    get room() {
        return  getParentOfType(cycle, Room);
    }
})).actions(self => {
    return {
        update: function (updated) {
            Object.keys(updated).forEach(key => self[key] = updated[key]);
        },
        rename: flow(function* (name) {
            yield api.patch("cycle", {id: self.id, name})
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        }),
        setProgram: flow(function* (programId) {
            yield api.patch("cycle", {id: self.id, programId})
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        }),
        setStart: flow(function* (startDate) {
            yield api.patch("cycle", {id: self.id, startDate})
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        }),
        switchMode: flow(function* (mode, item, parameters) {
            yield api.patch("cycle/mode", {id: self.id, mode, item, parameters})
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        }),
        // startNow: flow(function* () {
        //     yield api.put("cycle", {id: self.id})
        //         .then(r => self.update(r.data))
        //         .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        // }),
        start: flow(function* (startAt) {
            yield api.put("cycle", {id: self.id, startAt})
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        }),
        terminate: flow(function* () {
            yield api.post(`cycle/${self.id}/stop`)
                .then(r => self.update(r.data))
                .catch(e => swal({title: "Error", text: e?.message, icon: "error"}));
        })
    }
});

const Remote = types.model("Remote",
    {
        id: types.identifierNumber,
        remoteId: types.number,
        name: types.optional(types.string, ""),
        description: types.optional(types.string, ""),
        sensors: types.maybeNull(types.number),
        lamps: types.maybeNull(types.number),
        online: types.maybeNull(types.boolean)
    })
    .views(self => ({
        get room() {
            try {
                return getParentOfType(self, Room)
            } catch (e) {
                return null;
            }
        }
    }))
    .actions(self => {
        return {
            rename: flow(function* (name) {
                yield api.patch("remote", {id: self.id, name: name}).then(response => response.data);
                self.name = name;
            }),
            setOnline: flow(function* (online) {
                self.online = online;
            }),
        }
    });

const Room = types.model("Room", {
    id: types.identifierNumber,
    name: types.string,
    description: types.string,
    remotes: types.array(Remote),
    cycles: types.array(Cycle),
}).views(self => ({
    getCycle(id) {
        return self.cycles.find(cycle => '' + cycle.id === id)
    },
    get remoteCount() {
        const length = self?.remotes?.length;
        return length + (length === 1 ? " remote" : " remotes");
    },
    get sensorCount() {
        const length = self?.remotes?.map(remote => remote.sensors).filter(s => !!s).reduce((s, a) => s + a, 0);
        return length + (length === 1 ? " sensor" : " sensors");
    },
    get lightCount() {
        const length = self?.remotes?.map(remote => remote.lamps).filter(s => !!s).reduce((s, a) => s + a, 0);
        return length + (length === 1 ? " light" : " lights");
    },
    get inFlight() {
        return self?.cycles.find(cycle => cycle.status === "IN_FLIGHT");
    }
}))
    .actions(self => {
        return {
            load: flow(function* () {
                self.cycles = yield api.get(`cycle?room=${self.id}`).then(r => r.data);
                self.remotes = yield api.get(`remote?roomId=${self.id}`).then(r => r.data);
            }),
            remove: (function (remote) {
                const index = self.remotes.findIndex(r => r.id === remote.id);
                self.remotes.splice(index, 1);
            }),
            edit: flow(function* (updatedRoom) {
                const room = yield api.patch("room", {id: self.id, ...updatedRoom}).then(r => r.data);
                Object.keys(room).forEach(key => self[key] = room[key]);
            }),
            createCycle: flow(function* (name) {
                const cycle = yield api.post("cycle", {name, roomId: self.id}).then(r => r.data);
                self.cycles.unshift(cycle);
            }),
            deleteCycle: flow(function* (id) {
                yield api.delete("/cycle?id=" + id);
                const index = self.cycles.findIndex(c => c.id === id);
                self.cycles.splice(index, 1);
            }),
            afterCreate: flow(function* () {
                yield self.load();
            }),
        }
    })

const Project = types.model("Project", {
    id: types.identifierNumber,
    name: types.string,
    rooms: types.array(types.safeReference(Room)),
    createdOn: types.string
})
    .views((self) => ({
        getRoom(id) {
            return self.rooms.find(room => '' + room.id === id)
        },
        get activeCycles() {
            return self.rooms.flatMap(room => room.cycles).filter(cycle => cycle.status === "IN_FLIGHT").length;
        },
        get nearestHarvest() {
            const filter = self.rooms.flatMap(room => room.cycles)
                .filter(value => !!value.minutesToHarvest && value.minutesToHarvest > 0)
                .sort((a, b) => a.minutesToHarvest - b.minutesToHarvest);
            return filter[0]?.leftToHarvest || "-";
        },
        get power() {
            return "-";
        }
    }))
    .actions((self) => ({
        addRoomsToProject: flow(function* (roomIds) {
           const project = yield api.put("project", {projectId: self?.id, roomIds}).then(res => res.data);
           self.rooms = project.rooms
        })
    }));

const RootStore = types.model("RootStore", {
    projects: types.array(Project),
    rooms: types.array(Room),
    remotesWithoutRooms: types.array(Remote)
})
    .views(self => ({
        getProject(id) {
            return self.projects.find(project => '' + project.id === id)
        },
        getRoom(id) {
            return self.rooms.find(room => '' + room.id === id)
        }
    }))
    .actions(self => {
        return {
            load: flow(function* () {
                self.remotesWithoutRooms = yield api.get("remote/free").then(response => {
                    if (response.data.length === 0) return [];
                    return response.data;
                });
                self.rooms = yield api.get("room").then(response => response.data);
                self.projects = yield api.get("project").then(response => response.data);
            }),
            afterCreate: flow(function* () {
                yield self.load();
            }),
            createProject: flow(function* (name, rooms) {
                const project = yield api.post("project", {name, rooms}).then(res => res.data);
                self.projects.unshift(project)
            }),
            createRoom: flow(function* (room) {
                if (!room.name) throw new Error("Room name is require");
                const createdRoom = yield api.post("/room", room).then(value => value.data);
                self.rooms.unshift(createdRoom);
            }),
            deleteRoom: flow(function* (id) {
                yield api.delete("/room/" + id);
                const index = self.rooms.findIndex(r => r.id === id);
                self.rooms.splice(index, 1);
            }),
            changeRemoteRoom: flow(function* (remote, newRoom) {
                const oldRoom = remote.room;
                yield api.put("remote", {id: remote.id, room: newRoom?.id}).then(r => r.data);
                if (!!oldRoom) oldRoom.remove(remote);
                if (!!newRoom) {
                    newRoom.load();
                } else {
                    self.remotesWithoutRooms = yield api.get("remote/free").then(response => {
                        if (response.data.length === 0) return [];
                        return response.data;
                    });
                }
            }),
            deleteRemote: flow(function* (id) {
                yield api.delete(`remote?id=${id}`);
                self.remotesWithoutRooms = yield api.get("remote/free").then(response => {
                    if (response.data.length === 0) return [];
                    return response.data;
                });
            })
        }
    })

export const rootStore = RootStore.create({});

export function createRootStore() {
    rootStore.load();
}

makeInspectable(rootStore);






