import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import logo from "../../images/logo_on_circle.png";
import {authStore} from "../../store/AuthStore";
import {Alert, Button, FormCheck, FormGroup} from "react-bootstrap";
import {observer} from "mobx-react-lite";

export default observer(function Login() {

    let history = useHistory();
    let from = history.location.state;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();

    const [agree, setAgree] = useState(false);

    useEffect(() => setError(null), [email, password]);

    //редирект на профиль если залогиненый юзер пытается перейти на страничку логина
    useEffect(() => {
        console.log("редирект на профиль если залогиненый юзер пытается перейти на страничку логина", authStore.auth ,from);
        authStore.auth && history.push('/')
    }, [from]);

    //редирект после успешной аутентификации на станичку откуда пришел юзер
    useEffect(() => {
        console.log("редирект после успешной аутентификации на станичку откуда пришел юзер", authStore.auth, from);
        // if (authStore.isAuth()) {
        //     history.push(!!from ? from : '/');
        // }
    }, [authStore.auth]);

    const onLoginBtnClick = async () => {
        await authStore.login(email, password, agree)
            .catch(reason => {
                setError("Incorrect login or password");
            });
    };

    const authForm = <>
        <div className="mb-3">
            <h1 style={{color: 'rgb(255, 255, 255)'}}>Welcome to Growor</h1>
        </div>
        <h4 className="mb-4" style={{color: '#191c34'}}>Sign in by entering information below</h4>
        {error && (
            <Alert
                variant="danger"
                className="solid alert-dismissible fade show"
            >
                <strong>Error.</strong> {error}
            </Alert>
        )}
        <form>

            <div className="form-group">
                <div className="input-group mb-3 input-o">
                    <div className="input-group-prepend"><span className="input-group-text"><i className={'fa fa-user'}/></span></div>
                    <input type="text" className="form-control" placeholder="Username" onChange={(e) => setEmail(e.target.value)}/>
                </div>
            </div>
            <div className="form-group">
                <div className="input-group mb-3 input-o">
                    <div className="input-group-prepend"><span className="input-group-text"><i className={'fa fa-lock'}/></span></div>
                    <input type="password"
                           className="form-control"
                           placeholder="Password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </div>

            <div className="text-center">
                <button
                    type="button"
                    className="btn btn-secondary btn-block"
                    onClick={onLoginBtnClick}
                >
                    Sign In
                </button>
            </div>
        </form>
    </>

    const confirmForm = <>
        <div className="mb-3">
            <h3 style={{color: 'rgb(255, 255, 255)'}}>To continue, you must agree to the Growor Terms and Conditions and Privacy Policy</h3>
        </div>
        <form>
            <FormGroup className={"text-white mb-3"}>
                <FormCheck type="checkbox" onChange={e => setAgree(e.target.checked)} label={<>
                        I confirm that I have read, consent and agree to Growor&nbsp;
                        <a href="https://growor.com/terms-and-conditions/" target="_blank" rel="noreferrer" className="text-primary-green text-nowrap">Terms and Conditions</a>
                        &nbsp;and&nbsp;
                        <a href="https://growor.com/privacy/" target="_blank" rel="noreferrer" className="text-primary-green text-nowrap">Privacy Policy</a>
                </>}/>
            </FormGroup>

            <div className="d-flex justify-content-between">
                <Button variant="danger" onClick={() => {
                    authStore.setDialog(false)
                }}>DISAGREE</Button>
                <Button disabled={!agree} variant="success" onClick={() => {
                    onLoginBtnClick().then(() => authStore.setDialog(false));
                }}>AGREE</Button>
            </div>
        </form>
    </>

    return (
        <div className="login-wrapper">
            <div className="login-aside-left">
                <Link to="/" className="login-logo">
                    <img src={logo} alt="" width={"100%"} />
                </Link>
                <div className="login-description">
                    <h1 className="text-white mb-4">The industries first cultivation standard</h1>
                    <p className="fs-12"> Growor provides a universal technological solution for indoor & greenhouse farming. Holding multiple patents, our technology allows for the manipulation of the entire growing process, leading to increased production yield, enhanced fruit quality, increased health improvement and repeatability - with each and every cycle.</p>
                    <ul className="social-icons">
                        <li><a href={'mailto:info@growor.com'}><i className="fa fa-envelope"/></a></li>
                        <li><a href={'https://www.facebook.com/GroworInc'}><i className="fa fa-facebook"/></a></li>
                        <li><a href={'https://www.instagram.com/growor_official/'}><i className="fa fa-instagram"/></a></li>
                        <li><a href={'https://www.linkedin.com/company/43233542'}><i className="fa fa-linkedin"/></a></li>
                    </ul>
                    <div className="links mt-3">
                        <a target={"_blank"} href={'https://growor.com/terms-and-conditions/'} className="text-white mr-4">Terms and Conditions</a>
                        <a target={"_blank"} href={'https://growor.com/privacy/'} className="text-white mr-4">Privacy Policy</a>
                        <a target={"_blank"} href={'https://growor.com/contacts/'} className="text-white mr-4">Contacts</a>
                    </div>
                </div>
            </div>
            <div className="login-aside-right" style={{background: 'url(https://growor.com/m/uploads/keys/upload-efdf369e-89d0-4f65-9801-ef0c12ad8884_cX-0_cY-0_cW-1570_cH-992.jpg)'}}>
                <div className="row m-0 justify-content-center h-100 align-items-center no-gutters">
                    <div className="col-xl-6 col-xxl-8">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        {!authStore.dialog && authForm}
                                        {!!authStore.dialog && confirmForm}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

});
