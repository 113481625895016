import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {api} from "../../../api/restConfig";
import {profileStore} from "../../../store/ProfileStore";

export function EmergencyAlerts({cycleID}) {

    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (!cycleID) return;
        api.get(`/alert/emergency/${cycleID}`).then(res => {
            if (!!res.data) setAlerts(res.data);
        })
    }, [cycleID]);

    return <Card>
        <Card.Header className={"border-0 pb-0"}>
            <Card.Title className={"text-danger text-uppercase"}>
                <i className={"mdi mdi-alert-box scale2 mr-2"}/> Emergency Alerts
            </Card.Title>
        </Card.Header>
        <Card.Body className={"widget-media p-4"}>
            {(!alerts || alerts.length === 0) && <h5 className={"text-center"}><i className={"fa fa-thumbs-up"}/> Everything alright</h5>}

            <ul className={"timeline"}>
                {alerts.map(alert => <li key={`${alert.deviceId}`}><Item device={alert.device} deviceID={alert.deviceId} time={profileStore.datetime(alert?.time)}/></li>)}
            </ul>
        </Card.Body>
    </Card>;
}

function Item({device, deviceID, time}) {

    let title, icon, color;
    switch (device) {
        case "LAMP": {
            title = "LAMP LOST";
            icon = "mdi mdi-led-variant-off";
            color = "warning";
            break;
        }
        case "SENSOR": {
            title = "SENSOR LOST";
            icon = "mdi mdi-server-network-off"
            color = "warning";
            break;
        }
        case "REMOTE": {
            title = "REMOTE OFFLINE";
            icon = "mdi mdi-server-remove";
            color = "danger";
            break;
        }
        case "CONNECTION": {
            title = "Connection lost";
            icon = "mdi mdi-signal-off";
            color = "danger";
            break;
        }
    }

    return <div className="timeline-panel">
        <div className={`media mr-2 media-${color}`}><i className={`${icon}`}/></div>
        <div className="media-body">
            <h5 className="mb-1">
                <span className={`text-${color} text-uppercase`}>{title}</span>
            </h5>

            {!!deviceID && <h6 className="mb-1"><small>ID: </small>{deviceID}</h6>}

            <small className="d-block">
                {time}
            </small>
        </div>
    </div>
}
