import React, {Fragment, useEffect, useState} from 'react';
import {Breadcrumb, Button, Card, Col, ListGroup, Row} from 'react-bootstrap';
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {rootStore} from "../../../store/RootStore";
import {routeStore} from "../../../store/RouteStore";
import {AddRoomToProjectModal} from "../../components/project/AddRoomToProjectModal";

const Project = observer(function Project() {

    const {id} = useParams();

    const project =  rootStore.getProject(id);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        routeStore.setLocation("Projects")
    }, [])

    return (
        <Fragment>
            <Row className={"page-titles mx-0"}>
                <Col sm={6} className={"p-md-0"}>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{to: "/projects"}}>Projects</Breadcrumb.Item>
                        <Breadcrumb.Item active>{project?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col sm={6} className={"justify-content-end d-flex p-md-0 mt-2 mt-sm-0"}>
                    <Button
                        variant="secondary"
                        className="rounded mr-3 mb-sm-0 mb-2"
                        onClick={() => setShowModal(true)}
                    >
                        <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Add Growing Area
                    </Button>
                </Col>
                <AddRoomToProjectModal project={project} show={showModal} setShow={setShowModal}/>
            </Row>

            <Row>
                {project?.rooms.slice().sort((a, b) => a.id - b.id).map(room => {
                    let active = !!room.inFlight;

                    const subIcon = !active
                        ? <i className="las la-pause scale5 text-warning"/>
                        : <i className="las la-play scale5 text-success"/>;

                    return <Col xl={4} lg={6} key={room.id}>
                            <Card className={"project-boxed"} as={Link} to={"/projects/" + project.id + "/room/" + room.id}>
                                <Card.Header className={"align-items-start"}>
                                    <div>
                                        <h6 className="fs-18 font-w500 mb-3">
                                            {room.name}
                                        </h6>
                                        <div className="text-dark fs-14 text-nowrap">
                                            {room.description}&nbsp;
                                        </div>
                                    </div>
                                    <Card.Subtitle>{subIcon}</Card.Subtitle>
                                </Card.Header>

                                <Card.Body className={"p-0 pb-3"}>
                                    <div className={"px-4 py-2"}>
                                        <Row>
                                            <Col md={6}>
                                                <p className={"text-muted mb-2"}>In flight</p>
                                                <h4 className={"title font-w600 mb-2"}>{room?.inFlight?.name || "-"}</h4>
                                            </Col>
                                            <Col md={6}>
                                                <p className={"text-muted mb-2"}>End of cycle</p>
                                                <h4 className={"title font-w600 mb-2"}>{room?.inFlight?.leftToHarvest || "-"}</h4>
                                            </Col>
                                        </Row>

                                    </div>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item><i className={"flaticon-381-television mr-2 text-success"}/>{room.remoteCount}</ListGroup.Item>
                                        <ListGroup.Item><i className={"flaticon-381-network-3 mr-2 text-warning"}/>{room.sensorCount}</ListGroup.Item>
                                        <ListGroup.Item><i className="las la-lightbulb scale5 mr-2 text-secondary"/>{room.lightCount}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>

                            </Card>
                        </Col>
                    }
                )}
            </Row>


        </Fragment>
    )
})
export default Project;
