import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";

export function EditRemote({show, setShow, remote}) {

    const handleClose = () => setShow(false);

    const [name, setName] = useState(remote?.name);
    const [error, setError] = useState();

    function editRemote() {
        remote.rename(name)
            .then(__ => setShow(false))
            .catch(reason => setError(reason.message));
    }

    return (
        <div>
            <Modal className="modal fade" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Remote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyPress={event => {
                        if (event.code === "Enter") {
                            event.preventDefault();
                            editRemote();
                        }
                    }}>
                        <Form.Group>
                            <Form.Label className="text-black font-w500">Remote Name</Form.Label>
                            <Form.Control maxlength="50" aria-describedby="nameHelp"
                                          defaultValue={remote?.name}
                                          onChange={(e) => {
                                              setName(e.target.value);
                                          }}
                            />
                            <Form.Text id="nameHelp" muted>
                                Remote name must be less than 50 characters long, and
                                must not contain special characters, or emoji.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                    {error && (
                        <Alert
                            variant="danger"
                            className="solid alert-dismissible fade show"
                        >
                            <strong>Error.</strong> {error}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button block variant="secondary" onClick={() => {
                        editRemote();
                    }}>
                        SAVE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
