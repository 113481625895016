export function minutesToHuman(minutes) {
    if (minutes === 1440) return "24h";
    const d = Math.floor(minutes / 1440);
    const h = Math.floor(minutes / 60) - (d * 24);
    const m = minutes - (d * 24 * 60) - (h * 60);

    if (d > 1 && h===0 && m===0) return `${d}d`;

    return (d > 0 ? `${d}d ` : '') + '' + ((d > 0 || h > 0) ? `${h}h ` : '') + '' + (m > 0 ? `${m}m ` : '')
}
