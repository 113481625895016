import {types} from "mobx-state-tree"

const LoaderStore = types.model("LoaderStore",
    {
        load: types.boolean
    })
    .actions((store) => ({
        start() {
            store.load = true;
        },
        stop() {
            store.load = false;
        }
    }))

export const loaderStore = LoaderStore.create({
    load: false
})
