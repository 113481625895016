/// Menu
import MetisMenu from "metismenujs";
import React, {Component, useContext, useEffect, useRef} from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import {Link} from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import {ThemeContext} from "../../../context/ThemeContext";
import {routeStore} from "../../../store/RouteStore";
import {observer} from "mobx-react-lite";
import {authStore} from "../../../store/AuthStore";
import {sideBarStore} from "../../../store/SideBarStore";

//import profile from "../../../images/Untitled-1.jpg";
class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const SideBar = () => {

    const {
        iconHover,
        sidebarposition,
        headerposition,
        sidebarLayout
    } = useContext(ThemeContext);

    const ref = useRef();

    function hideSidebar() {
        sideBarStore.close();
    }

    useEffect(() => {
        const wrapper = document.querySelector("#main-wrapper");
        if(sideBarStore.isOpen) wrapper.classList.add("menu-toggle");
        else wrapper.classList.remove("menu-toggle");
    }, [sideBarStore.isOpen])

    let scrollPosition = useScrollPosition();

    return (
        <div
            ref={ref}
            className={`deznav ${iconHover} ${
                sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                    ? scrollPosition > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >

            <PerfectScrollbar className="deznav-scroll">

                <MM className="metismenu" id="menu">

                    <li className={`${routeStore.location === 'Projects' ? "mm-active" : ""}`} onClick={hideSidebar}>
                        <Link to="/projects">
                            <i className="flaticon-046-home"/>
                            <span className="nav-text">Projects</span>
                        </Link>
                    </li>

                    <li className={`${routeStore.location === 'History' ? "mm-active" : ""}`} onClick={hideSidebar}>
                        <Link to="/history" >
                            <i className="flaticon-005-back-arrow"/>
                            <span className="nav-text">History</span>
                        </Link>
                    </li>

                    <li className={`${routeStore.location === 'Programs' ? "mm-active" : ""}`} onClick={hideSidebar}>
                        <Link to="/programs"> <i className="flaticon-006-brightness"/><span
                            className="nav-text">Programs</span></Link>
                    </li>

                    <li className={`${routeStore.location === 'Devices' ? "mm-active" : ""}`} onClick={hideSidebar}>
                        <Link to="/devices">
                            <i className="fa fa-server"/>
                            <span className="nav-text">Devices</span>
                        </Link>
                    </li>

                    <li className={`${routeStore.location === 'Reports' ? "mm-active" : ""}`} onClick={hideSidebar}>
                        <Link to="/reports">
                            <i className="fa fa-pie-chart"/>
                            <span className="nav-text">Reports</span>
                        </Link>
                    </li>

                    {authStore.admin && (
                        <li className={`${routeStore.location === 'Users' ? "mm-active" : ""}`} onClick={hideSidebar}>
                            <Link to="/users">
                                <i className="fa fa-users"/>
                                <span className="nav-text">Users</span>
                            </Link>
                        </li>
                    )}

                </MM>

            </PerfectScrollbar>
        </div>
    );
};

export default observer(SideBar);
