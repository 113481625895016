export const parameterIcon = (name) => {
    switch (name) {
        case "CO2": return <i className="mdi mdi-periodic-table-co2 scale3 bg-warning" aria-hidden="true"/>;
        case "T": return <i className="mdi mdi-thermometer-lines scale3 bg-danger" aria-hidden="true"/>;
        case "RH": return <i className="mdi mdi-water-percent scale3 bg-info" aria-hidden="true"/>;
        case "P": return <i className="las la-compress-arrows-alt scale3 bg-secondary" aria-hidden="true"/>;
        default: return <i className="las la-question-circle" aria-hidden="true" />;
    }
}

export const parameterTitle = (name) => {
    switch (name) {
        case "CO2": return "Carbon Dioxide Level";
        case "T": return "Temperature" ;
        case "RH": return "Relative Humidity";
        case "P": return "Pressure";
        default: return "";
    }
}
