import React, {Fragment} from "react";

import {Link} from "react-router-dom";
import Loader from "../../components/Loader";
import {sideBarStore} from "../../../store/SideBarStore";
import {observer} from "mobx-react-lite";

const NavHader = () => {

    return (
        <div className="nav-header d-flex justify-content-end" style={{flexDirection: "column"}}>
            <Link to="/" className="brand-logo d-none d-md-flex">
                <Fragment>
                    <div style={{
                        textAlign: 'center',
                        height: "60px",
                        width: "60px",
                        borderRadius: "25%",
                        color: "#000",
                        background: "#43DC80",
                        // padding: "15px",
                        fontSize: '1.9em'
                    }}>
                        gr
                    </div>
                </Fragment>
            </Link>

            <div
                className="nav-control"
                style={{
                    right: "0"
                }}
                onClick={() => {
                    sideBarStore.toggle();
                }}
            >
                <div className={`hamburger ${sideBarStore.isOpen ? "is-active" : ""}`}>
                    <span className="line"/>
                    <span className="line"/>
                    <span className="line"/>
                </div>
            </div>

            <Loader/>
        </div>
    );
};

export default observer(NavHader);
