import {TOKEN} from "./api";
import {loaderStore} from "../store/LoaderStore";

export const BASE_URL = process.env.REACT_APP_API_HOST;

const axios = require('axios').default;
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers = {'Content-Type': 'application/json'};

export const apiGhost = axios.create();
export const api = axios.create();

apiGhost.interceptors.request.use((config) => config, (error) => Promise.reject(error));
apiGhost.interceptors.response.use((response) => {
    console.debug("RES");
    return response
}, (error) => Promise.reject(error.response.data[0]));

api.interceptors.request.use((config) => {
        let token = localStorage.getItem(TOKEN);
        if (token) config.headers.Authorization = `Bearer ${token}`;

        loaderStore.start();
        return config;
    },
    (error) => Promise.reject(error)
);
api.interceptors.response.use(
    (response) => {
        loaderStore.stop();
        return response;
    },
    (error) => {
        loaderStore.stop();
        if (error?.response?.status === 401) {
            localStorage.removeItem(TOKEN);
            window.location.href = "/";
        }
        else {
            return Promise.reject(error?.response?.data);
        }
    }
);

