import {Container, Jumbotron} from "react-bootstrap";
import React from "react";

export function JumbotronPlaceholder({title, text, code}) {
    return <Jumbotron fluid className="bg-secondary-light text-white">
        <Container>
            <h1>{title}</h1>
            <p>
                <i className="fa fa-info-circle mr-2"/>
                {text}
            </p>
            {code}
        </Container>
    </Jumbotron>
}
