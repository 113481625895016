import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Alert, Modal} from "react-bootstrap";
import {loaderStore} from "../../../store/LoaderStore";
import {usersStore} from "../../../store/UsersStore";
import moment from "moment-timezone";
import {profileStore} from "../../../store/ProfileStore";

export const CreateUser = () => {
    const [newUser, setNewUser] = useState(false);

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    const [role, setRole] = useState("Grower");
    const [temperatureUnit, setTemperatureUnit] = useState("C");
    const [timeZone, setTimeZone] = useState("Asia/Jerusalem");

    const [error, setError] = useState();

    useEffect(() => {
        setError(null);
    }, [newUser, username, password, confirm, role]);

    return (
        <div className="">
            <Link to={"#"} onClick={() => setNewUser(true)}
                  className="btn btn-secondary rounded text-white text-nowrap">
                <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create User
            </Link>
            <Modal className="modal fade" show={newUser} onHide={setNewUser}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create User</h5>
                            <button type="button" className="close" onClick={() => setNewUser(false)}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label className="text-black font-w500">Email</label>
                                    <input type="text" className="form-control"
                                           onChange={(e) => setUsername(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label className="text-black font-w500">Password</label>
                                    <input type="password" className="form-control"
                                           onChange={(e) => setPassword(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label className="text-black font-w500">Confirm Password</label>
                                    <input id="user_create_confirm" type="password" className="form-control"
                                           onChange={(e) => setConfirm(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label className="text-black font-w500">Role</label>
                                    <select defaultValue={role} className="form-control" onChange={(e) => {
                                        setRole(e.target.value)
                                    }}>
                                        <option>Grower</option>
                                        <option>Super Admin</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="text-black font-w500">Temperature Unit</label>
                                    <select defaultValue={temperatureUnit} className="form-control" onChange={(e) => {
                                        setTemperatureUnit(e.target.value)
                                    }}>
                                        <option label={"°C"}>C</option>
                                        <option label={"°F"}>F</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="text-black font-w500">Temperature Unit</label>
                                    <select defaultValue={timeZone} className="form-control" onChange={(e) => {
                                        setTimeZone(e.target.value)
                                    }}>
                                        {moment.tz.names().map(tz=><option selected={profileStore.tz===tz}>{tz}</option>)}
                                    </select>
                                </div>

                                {error && (
                                    <Alert
                                        variant="danger"
                                        className="solid alert-dismissible fade show"
                                    >
                                        <strong>Error.</strong> {error}
                                    </Alert>
                                )}

                                <div className="form-group">
                                    <button type="button" className="btn btn-block btn-secondary"
                                            disabled={loaderStore.load} onClick={() => {
                                        if (password !== confirm) {
                                            setError("Password does not match");
                                            return;
                                        }

                                        usersStore.addUser({
                                            username: username,
                                            password: password,
                                            confirm: confirm,
                                            role: role,
                                            temperatureUnit: temperatureUnit,
                                            timezone: timeZone
                                        })
                                            .then(() => setNewUser(false))
                                            .catch(reason => {
                                                setError(reason.message);
                                            });

                                    }}>CREATE USER
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
