import {Button, Card, Media} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {api} from "../../../api/restConfig";
import {profileStore} from "../../../store/ProfileStore";
import swal from "sweetalert";
import {toF} from "../../utils/TemperatureConverter";

export function SensorsOverlimit({cycleID}) {

    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (!cycleID) return;

        api.get(`/alert/overlimit/${cycleID}`)
            .then(res => setAlerts(res.data))
            .catch(reason => console.error(reason));

    }, [cycleID])

    const OverlimitItem = ({alert}) => {

        const {parameter, createdAt, closedAt, direction, value} = alert;
        const val = (parameter === "T" && profileStore.f) ? toF(value).toFixed(0) : value;

        function icon() {
            if (parameter === 'CO2') return <div className="media mr-2 media-warning">CO₂</div>
            if (parameter === 'P') return <div className="media mr-2 media-info">P</div>
            if (parameter === 'RH') return <div className="media mr-2 media-secondary">RH</div>
            if (parameter === 'T') return <div className="media mr-2 media-danger">t°</div>
        }

        function dir() {
            if (direction === "HI") return "High"
            if (direction === "LO") return "Low"
        }

        function title() {
            if (parameter === 'CO2') return `${dir()} CO₂ level`;
            if (parameter === 'P') return `${dir()} Pressure`;
            if (parameter === 'RH') return `${dir()} Humidity`;
            if (parameter === 'T') return `${dir()} Temperature`;
        }

        return <li key={alert?.id}>

            <div className="timeline-panel">
                {icon()}
                <Media.Body>
                    <h5 className="mb-1">
                        {title()} {!!val && `(${val})`}
                    </h5>
                    <small className="d-block">
                        {profileStore.calendar(createdAt)} {closedAt && `- ${profileStore.calendar(closedAt)}`}
                    </small>
                </Media.Body>

            </div>
        </li>
    }

    return <Card>
        <Card.Header className={"border-0 pb-0"}>
            <Card.Title className={"text-warning text-uppercase"}>
                <i className={"mdi mdi-alarm-light scale2 mr-2"}/> Out Of Bounds
            </Card.Title>
            <Card.Subtitle>
                <Button
                    hidden={true}
                    variant="outline-dark"
                    className={`close ml-2`}
                    style={{verticalAlign: "baseline", float: "inherit"}}
                    onClick={(e) => {
                        e.preventDefault();
                        swal({
                            title: "Are you sure?",
                            text: "All previous warnings will be lost",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                api.delete(`/alert/${cycleID}`)
                                    .then(res => {
                                        setAlerts(res.data);
                                        swal("Closed warnings has been cleared", {icon: "success"});
                                    })
                                    .catch(reason => swal("Cannot clear warnings", {icon: "error"}));
                            }
                        })
                    }}
                >
                    <i className={`fa fa-trash`}/>
                </Button>
            </Card.Subtitle>
        </Card.Header>
        <Card.Body className={"widget-media p-4"}>
            {(!alerts || alerts.length === 0) && <h5 className={"text-center"}><i className={"fa fa-thumbs-up"}/> Everything alright</h5> }
            <ul className={"timeline"}>
                {alerts.map((alert, i) => <OverlimitItem key={`alert-${i}`} alert={alert} /> )}
            </ul>
        </Card.Body>
    </Card>;
}
