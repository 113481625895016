import React, {Fragment, useEffect, useState} from 'react';
import {Breadcrumb, Button, ButtonGroup, Col, Container, Jumbotron, Row} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {rootStore} from "../../../store/RootStore";
import {routeStore} from "../../../store/RouteStore";
import {CycleChart} from "../../components/cycle/CycleChart";
import {ConsumedElectricity} from "../../components/cycle/ConsumedElectricity";
import {SensorsOverlimit} from "../../components/cycle/SensorsOverlimit";
import swal from "sweetalert";
import {EmergencyAlerts} from "../../components/cycle/EmergencyAlerts";
import {Phase} from "../../components/cycle/Phase";
import {CycleName} from "./CycleName";
import {ModeButton} from "./ModeButton";
import ManualModePanel from "./ManualModePanel";
import {api} from "../../../api/restConfig";
import {ProtocolComponent} from "../Programs/ProtocolComponent";
import {loaderStore} from "../../../store/LoaderStore";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RemotePanel from "../../components/cycle/RemotePanel";
import moment from "moment";
import {profileStore} from "../../../store/ProfileStore";

export default observer(function CyclePage() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, handleDateChange] = useState(moment(new Date()).tz(profileStore.tz).format('lll'));

    const {projectID} = useParams();
    const {roomID} = useParams();
    const {cycleID} = useParams();

    const room = rootStore.getRoom(roomID);

    const [cycle, setCycle] = useState();
    const [remotes, setRemotes] = useState();
    const [mode, setMode] = useState();

    useEffect(() => {
        routeStore.setLocation("Cycle")
    }, [])

    useEffect(() => {
        if (!!room && room?.cycles?.length > 0)
            setCycle(room?.getCycle(cycleID));

    }, [cycleID, room, room?.cycles?.length]);

    useEffect(() => {
        if (!!room && room?.remotes?.length > 0)
            setRemotes(room?.remotes);

    }, [room, room?.remotes?.length])

    useEffect(() => {
        setMode(cycle?.mode);
        handleDateChange(cycle?.startedAt || moment(new Date()).tz(profileStore.tz).format('lll'));
    }, [cycle, cycle?.protocol, cycle?.terminated, cycle?.status])

    const startPanelActive = <>
        <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <span className="fs-14">Start date</span>
            <h6 className={"text-nowrap"}>
                {cycle?.startAtString}
                <Button
                    variant="outline-dark"
                    size={"sm"}
                    className={`close ml-2`}
                    style={{verticalAlign: "baseline", float: "inherit"}}
                    onClick={() => setIsOpen(true)}
                >
                    <span><i className={`fa fa-edit`}/></span>
                </Button>
            </h6>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    label=""
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    format="MMM dd yyyy hh:mm a"
                    inputVariant="standard"
                    variant="dialog"
                    value={selectedDate}
                    onChange={handleDateChange}
                    style={{display: "none"}}
                    onAccept={(date) => {
                        console.log(date.toISOString())
                        cycle?.setStart(date.toISOString());
                    }}
                />
            </MuiPickersUtilsProvider>
        </Col>
        <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <span className="fs-14">End of cycle</span>
            <h6 className={"text-nowrap"}>{cycle?.harvestAtString}&nbsp;
                <span
                    className={cycle?.minutesToHarvest > 0 ? "text-info" : "text-success"}>{cycle?.leftToHarvest}</span>
            </h6>
        </Col>
    </>;

    const panelTerminated = <>
        <Col xl={2} lg={3} md={3} sm={4} xs={6}>
            <span className="fs-14">Start date</span>
            <h6 className={"text-nowrap"}>{cycle?.startAtString}</h6>
        </Col>
        <Col xl={4} lg={3} md={3} sm={4} xs={6}>
            <span className="fs-14">End of cycle</span>
            <h6 className={"text-nowrap"}>
                <span className={"text-danger"}>TERMINATED</span>
            </h6>
        </Col>
    </>;

    const startPanelNew = <>
        <Col xl={6} lg={6} md={12} className={"d-flex mb-md-2 mb-sm-2 mb-xs-2"}>

            <div className={"d-flex"}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <DateTimePicker
                        label=""
                        format="MMM dd, yyyy hh:mm a"
                        inputVariant="standard"
                        variant="dialog"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </MuiPickersUtilsProvider>
            </div>

            <Button
                disabled={(!cycle?.protocol && cycle?.mode === "AUTO") || (!cycle?.manual && cycle?.mode === "MANUAL")}
                variant={"primary btn-square"}
                className={"ml-1"} size={"sm"}
                onClick={() => {
                    cycle?.start(moment(selectedDate).tz(profileStore.tz, true));
                }}>
                <i className={`fa fa-play text-white mr-2`}/>
                Start
            </Button>

        </Col>
    </>;

    const ProtocolSelector = () => {

        const [programs, setPrograms] = useState([]);

        useEffect(() => {
            Promise.all([api.get('program').then(res => res.data), api.get('program/shared').then(res => res.data)])
                .then(res => {
                    setPrograms(res.flatMap(value => value))
                })
        }, [])

        return <Jumbotron fluid className="bg-secondary-light text-white py-1">
            <Container>
                <h1>Choose a program for growing in automation mode</h1>
                <p>
                    Or switch to <span className={"text-primary-green cursor-pointer"}
                                       onClick={() => setMode("MANUAL")}>manual mode</span>
                </p>
                <ul>
                    {programs?.map(program => <li key={program.id} className={"cursor-pointer mr-3"} onClick={() => cycle.setProgram(program.id)}>
                        <ProtocolComponent protocol={program}/></li>)}
                </ul>
            </Container>
        </Jumbotron>
    }

    const AutoModePanel = () => {

        if (!cycle?.protocol)
            return <Col><ProtocolSelector/></Col>

        return <>
            {
                cycle?.protocol?.phases.map((phase, i) =>
                    <Col xl={2} lg={2} md={6} xs={6} className={"px-1"} key={`col-phase-${i}`}>
                        <Link key={`link-phase-${i}`} to={`/projects/${projectID}/room/${roomID}/cycle/${cycleID}/phase/${i}`}>
                            <Phase key={`phase-${i}`} phase={phase} isTerminated={cycle?.terminated}/>
                        </Link>
                    </Col>
                )
            }
        </>
    }

    const modePanel = <Row>
        {mode === "AUTO"
            ? <AutoModePanel/>
            :
            <ManualModePanel
                cycleMode={cycle?.mode}
                parameters={cycle?.manual?.parameters}
                item={cycle?.manual?.item}
                onApply={(item, parameters, callback) => {
                    cycle?.switchMode(cycle?.mode, item, parameters).then(callback);
                }}
            />
        }
    </Row>

    const ControlButtons = () => {

        function cancel() {
            setMode(cycle?.mode);
        }

        if (cycle?.mode === 'MANUAL' && mode === "AUTO")
            return <div className={"d-flex justify-content-end"}>
                <ButtonGroup size={"sm"}>
                    <Button variant={"danger"} onClick={() => cancel()}>Cancel</Button>
                    <Button disabled={loaderStore?.load} onClick={()=> cycle?.switchMode("AUTO")}>Switch to Auto</Button>
                </ButtonGroup>
            </div>

        if (cycle?.mode === 'AUTO' && mode === "MANUAL")
            return <div className={"d-flex justify-content-end"}>
                <ButtonGroup size={"sm"}>
                    <Button variant={"danger"} onClick={() => cancel()}>Cancel</Button>
                    <Button disabled={loaderStore?.load} onClick={()=> cycle?.switchMode("MANUAL")}>Switch to Manual</Button>
                </ButtonGroup>
            </div>

        return <ButtonGroup size="sm">

            <ModeButton active={cycle?.mode === 'MANUAL'}
                        disabled={cycle?.terminated || cycle?.status === "DONE"}
                        started={cycle?.status === "IN_FLIGHT"}
                        text={"Manual"}
                        onClick={() => setMode("MANUAL")}
            />
            <ModeButton active={cycle?.mode === 'AUTO'}
                        disabled={cycle?.terminated || cycle?.status === "DONE"}
                        started={cycle?.status === "IN_FLIGHT"}
                        text={"Auto"}
                        onClick={() => {
                            setMode("AUTO");
                        }}
            />
            <Button size="sm" variant={"danger"}
                    className={"btn-square mr-1"}
                    disabled={cycle?.status !== "IN_FLIGHT" || cycle?.terminated}
                    onClick={() => {
                        swal({
                            title: "Are you sure?",
                            text: "Growing Сycle will be stopped",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        }).then((confirm) => {
                            if (confirm) {
                                cycle.terminate();
                            }
                        })
                    }}>
                Terminate
            </Button>
        </ButtonGroup>
    }

    return (
        <Fragment>

            <Row className={"page-titles mx-0"}>
                <Col lg={12} className={"p-md-0 mt-2 mt-sm-0"}>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link}
                                         linkProps={{to: "/projects/" + projectID}}>{cycle?.projectName}</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={Link}
                                         linkProps={{to: "/projects/" + projectID + "/room/" + roomID}}>{cycle?.roomName}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{cycle?.name || cycleID}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <div className="mb-4">
                <CycleName cycle={cycle}/>

                <Row>
                    {cycle?.status === "NEW" ? startPanelNew : cycle?.terminated ? panelTerminated : startPanelActive}

                    <Col xl={6} lg={6} md={12}
                         className={"d-flex align-items-center justify-content-md-start justify-content-lg-end "}>
                        <ControlButtons />
                    </Col>
                </Row>
            </div>

            <Row>

                <Col xxl={9}>
                    {modePanel}

                    {cycle?.status !== "NEW" &&
                        <Row>
                            <Col xl={6} lg={12}>
                                <CycleChart name={`Temperature ${profileStore.f?"°F":"°C"}`} cycle={cycle} parameter={"T"}/>
                            </Col>

                            <Col xl={6} lg={12}>
                                <CycleChart name={"CO₂"} cycle={cycle} parameter={"CO2"}/>
                            </Col>

                            <Col xl={6} lg={12}>
                                <CycleChart name={"Humidity"} cycle={cycle} parameter={"RH"}/>
                            </Col>

                            <Col xl={6} lg={12}>
                                <CycleChart name={"Pressure"} cycle={cycle} parameter={"P"}/>
                            </Col>

                        </Row>
                    }


                </Col>

                <Col xl={3}>
                    <Row>
                        <Col xl={12}>
                            <ConsumedElectricity cycle={cycle}/>
                        </Col>

                        <Col xl={12}>
                            {!!remotes && !cycle?.terminated && <RemotePanel remotes={remotes}/>}
                        </Col>

                        <Col xl={12}>
                            <EmergencyAlerts cycleID={cycle?.id}/>
                        </Col>

                        <Col xl={12}>
                            <SensorsOverlimit cycleID={cycle?.id}/>
                        </Col>

                    </Row>
                </Col>

            </Row>


        </Fragment>
    )
});
