import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, ButtonGroup, Card, Col, Row} from "react-bootstrap";
import {CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {api} from "../../../api/restConfig";
import {socket, subscribe, unsubscribe} from "../../../api/socket";
import {profileStore} from "../../../store/ProfileStore";
import {toF} from "../../utils/TemperatureConverter";

export function CycleChart({name, parameter, cycle}) {

    const [data, setData] = useState([]);
    const [max, setMax] = useState(0);
    const [min, setMin] = useState(0);

    const [minY, setMinY] = useState(0);
    const [maxY, setMaxY] = useState(0);

    const [period, setPeriod] = useState(24);

    function convert(value) {
        if (parameter === "T" && profileStore.f) return toF(value).toFixed(1);
        return value;
    }

    useEffect(() => {

        if (!cycle) return;

        api
            .get(`chart?cycleID=${cycle?.id}&parameter=${parameter}&period=${period}`)
            .then(value => {
                setData(value.data.map(e => {
                    return {time: e.time, value: convert(e.value)}
                }));
            });

        const currentPhase = cycle?.currentPhase?.toJSON();
        if (!currentPhase) return;
        const param = currentPhase.parameters.find(par=> par.name === parameter);

        setMax(convert(param?.max));
        setMin(convert(param?.min));

    }, [cycle, parameter, period]);

    useEffect(() => {
        if (!cycle) return;

        const localPeriod = cycle.terminated ? 0 : 24;
        setPeriod(localPeriod);

        const room = `cycle.${cycle.id}.${parameter}`;

        function callback(e) {
            setData(prevState => [...prevState, {time: e.time, value: convert(e.value)}]);
        }

        subscribe(room);
        socket.on(room, callback);

        return () => {
            unsubscribe(room);
            socket.off(room, callback);
        }

    }, [cycle, parameter])

    useEffect(() => {
        if (data.length === 0) return;

        setMaxY(Math.round(Math.max(data.map(d => d.value).reduce((p, c, i, a) => p > c ? p : c), max)) + 1);
        setMinY(Math.round(Math.min(data.map(d => d.value).reduce((p, c, i, a) => p > c ? c : p), min)));

    }, [data, max, min]);

    const minDomain = period === 0 ? moment(cycle.startedAt).unix() * 1000 : moment().subtract(period, "hours").unix() * 1000;
    const maxDomain = cycle?.terminated ? Math.max(data.map(d => d.time)) : moment().unix() * 1000;


    return <Card key={`cycle-${parameter}`}>
        <Card.Body className={"pb-0"}>
            <Card.Title className={"text-white"}>{name}</Card.Title>
            <Card.Subtitle className={"text-right pb-2"}>
                <ButtonGroup size={"sm"}>
                    <Button variant="secondary " active={period===1} onClick={() => setPeriod(1)}>1h</Button>
                    <Button variant="secondary " active={period===6} onClick={() => setPeriod(6)}>6h</Button>
                    <Button variant="secondary " active={period===12} onClick={() => setPeriod(12)}>12h</Button>
                    <Button variant="secondary " active={period===24} onClick={() => setPeriod(24)}>24h</Button>
                    <Button variant="secondary " active={period===0} onClick={() => setPeriod(0)}>ALL</Button>
                </ButtonGroup>
            </Card.Subtitle>
            <ResponsiveContainer height={300}>
                <LineChart data={data} margin={{top: 0, right: -30, bottom: 0, left: -20}}>
                    <ReferenceLine yAxisId="left" y={min} stroke="#5e72e4" strokeDasharray="3 3"/>
                    <ReferenceLine yAxisId="left" y={max} stroke="#EE3232" strokeDasharray="3 3"/>

                    <Line isAnimationActive={false} yAxisId="left" type="stepBefore" dataKey="value" stroke="#43DC80" strokeWidth={1} dot={false}/>
                    {/*<Line yAxisId="left" type="monotone" dataKey="max" stroke="#EE3232" strokeWidth={2} dot={false}/>*/}
                    {/*<Line yAxisId="right" type="monotone" dataKey="f" stroke="#864AD1" strokeWidth={2} dot={false}/>*/}
                    <CartesianGrid stroke="#505060" strokeDasharray="3 3"/>
                    <XAxis dataKey="time"
                           tick={{stroke: "white", fontSize: 12}}
                           name={"Time"} type={"number"}
                           domain={[minDomain, maxDomain]}
                           tickFormatter={timeStr => moment(timeStr).format('DD MMM HH:mm')}/>
                    <YAxis yAxisId="left" tick={{stroke: "white", fontSize: 10}} domain={[minY, maxY]}/>
                    <YAxis yAxisId="right" orientation="right"/>
                    <Tooltip
                        labelFormatter={t => {
                            return <div>
                                <span>{moment(t/1).format('DD MMM HH:mm')}</span><br/>
                                <span>{profileStore.format(t/1, "DD MMM HH:mm z")}</span>
                            </div>
                        }}
                        formatter={(v,n,p) => [v, name]}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Card.Body>
        <Card.Footer className={"border-0 py-1"}>
            <Row>
                <Col xs={6} className={"text-blue"}>--- min: {min}</Col>
                <Col xs={6} className={"text-red"}>--- max: {max}</Col>
            </Row>
        </Card.Footer>
    </Card>;
}
