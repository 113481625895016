import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Jumbotron} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {routeStore} from "../../../store/RouteStore";
import {CreateProjectModal} from "../../components/project/CreateProjectModal";
import {rootStore} from "../../../store/RootStore";

const Jumbo = observer(function Jumbo({showCreate}) {

    const {rooms, projects} = rootStore;

    if (rooms.length === 0) {
        return <Jumbotron fluid className="bg-secondary-light text-white">
            <Container>
                <h1>Welcome to Growor Control Panel</h1>
                <p>
                    <i className="fa fa-info-circle mr-2"/>
                    To get started, connect your devices and create a growing area in the&nbsp;
                    <Link to={"/devices"} className={"text-primary-green"}>
                        device section
                    </Link>
                </p>
            </Container>
        </Jumbotron>
    }

    if (projects.length === 0) {
        return <Jumbotron fluid className="bg-secondary-light text-white">
            <Container>
                <h1>Congratulations!</h1>
                <h3>Now you can create your first project</h3>
                <Button
                    variant="secondary"
                    onClick={() => showCreate(true)}
                    className="rounded mb-sm-0 my-3">
                    <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create New Project
                </Button>
            </Container>
        </Jumbotron>
    }

    return <></>

});

const Projects = observer(function Projects() {

    const {rooms, projects} = (rootStore);

    const [newProject, setNewProject] = useState(false);

    useEffect(() => {
        routeStore.setLocation("Projects")
    }, [])

    return (
        <Fragment>
            <div className="row page-titles mx-0">
                <div className="col-sm-6 p-md-0">
                    {/*    */}
                </div>
                <div className="col-sm-6 p-md-0 justify-content-end mt-2 mt-sm-0 d-flex">
                    {
                        (rooms.length !== 0 && projects.length !== 0)
                        &&
                        <Button
                            variant="secondary"
                            onClick={() => setNewProject(true)}
                            className="rounded mr-3 mb-sm-0 mb-2">
                            <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Create New Project
                        </Button>
                    }
                    <CreateProjectModal show={newProject} setShow={setNewProject}/>
                </div>
            </div>

            <Jumbo showCreate={setNewProject}/>

            {projects.map(project => <Card key={project.id}>

                <Card.Body as={Link} to={"projects/" + project.id} className="project-info">

                    <Col xl={6} lg={12} sm={12} className={"my-2"}>
                        <p className={"text-primary mb-2"}>#P-{project.id}</p>
                        <h4 className={"title font-w600 mb-3"}>{project.name}</h4>
                        <div className={"text-dark"}>
                            <i className={"flaticon-025-dashboard mr-2"}/>{project.rooms.length} growing area{project.rooms.length!==1&&"s"}
                        </div>
                    </Col>

                     <Col xl={3} lg={6} sm={6} className={"my-2"}>
                        <div className="d-flex align-items-center">
                            <div className="power-ic">
                                <i className="fa fa-refresh bg-info" aria-hidden="true"/>
                            </div>
                            <div className="ml-3">
                                <span className={"text-muted"}>Active Cycles</span>
                                <h5 className="mb-0 pt-1 font-w500 text-black">
                                    {project.activeCycles}
                                </h5>
                            </div>
                        </div>
                    </Col>

                    <Col xl={3} lg={6} sm={6} className={"my-2"}>
                        <div className="d-flex align-items-center">
                            <div className="power-ic">
                                <i className="fa fa-leaf bg-warning" aria-hidden="true"/>
                            </div>
                            <div className="ml-3">
                                <span className={"text-muted"}>End of cycle</span>
                                <h5 className="mb-0 pt-1 font-w500 text-black">{project.nearestHarvest}</h5>
                            </div>
                        </div>
                    </Col>

                </Card.Body>

            </Card>)}


        </Fragment>
    )
})
export default Projects;
