import Select from "react-select";
import React, {useEffect, useState} from "react";

export const PhaseNameSelector = ({phaseName, onChange}) => {

    const options = [
        {value: "vega", label: "Vega", color: "#51a6f50d"},
        {value: "flowering", label: "Flowering", color: "#ff4c410d"},
    ];

    const [value, setValue] = useState();

    useEffect(() => {
        setValue(options.find(option => option.label === phaseName))
    }, [phaseName]);

    return <Select
        className={"col"}
        placeholder={"Select Phase"}
        value={value}
        onChange={onChange}
        options={options}
        styles={{
            menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#24292D'
            }),
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                return {
                    ...styles,
                    backgroundColor: isFocused ? "#636363" : "#24292D",
                    color: 'white',
                    cursor: 'pointer',
                    ':active': {
                        ...styles[':active'],
                        backgroundColor: "#24292D",
                    },
                };
            },
            singleValue: (styles, state) => {
                styles.color = 'white';
                return {...styles};
            },
            control: (styles, state) => {
                delete styles['&:hover'];
                delete styles['boxShadow'];
                styles.borderColor = '#636363';

                if (!!state?.selectProps?.value?.value)
                    styles.backgroundColor = state?.selectProps?.value?.color;
                else
                    styles.backgroundColor = '#24292D';

                styles.color = 'white';
                return ({...styles})
            }
        }}
    />
}
