import {types} from "mobx-state-tree"

const RouteStore = types.model("RouteStore",
    {
        location: types.string
    })
    .actions((store) => ({
        setLocation(path) {
            store.location = path;
        }
    }))

export const routeStore = RouteStore.create({
    location: ""
})
