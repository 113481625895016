import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {computeDurationHours, computeNightHours} from "../../utils/ItemHelper";

export function Phase({phase, isTerminated}) {

    const {name, items, status, daysToStart, loops, minutesToStart} = phase;

    const dayHours = computeDurationHours(items);
    const nightHours = computeNightHours(items);
    const lightHours = dayHours - nightHours;

    const daySegment = 100 * lightHours / dayHours;
    const nightSegment = 100 * nightHours / dayHours;

    const dayOffset = 75 - (nightSegment / 2);

    let style = {}
    let done = false;
    let current = false;

    const mid = moment().add((minutesToStart % 1440), "minutes").subtract(nightHours / 2, "hours");

    const mmt = moment();
    const mmtMidnight = mmt.clone().startOf('day');
    const diffMinutes = mmt.diff(mmtMidnight, 'minutes');

    const [ticker, setTicker] = useState((diffMinutes * 100 / 1440) - (nightSegment / 2));
    const [time, setTime] = useState("xx:xx");

    const [dx, setDx] = useState(100);
    const [dy, setDy] = useState(100);

    function calculate() {
        const mmt = moment();
        const mmtMidnight = mmt.clone().startOf('day');
        const diff = mmt.diff(mid, 'seconds');
        const dayPassedPercent = diff * 100 / 86400;

        const tick = dayPassedPercent - (nightSegment / 2);

        const h = tick * dayHours / 100;

        const duration = mmtMidnight.add(h, "hours").format("HH:mm")

        setTime(duration);

        if (tick > daySegment) setTicker(daySegment);
        else if (tick > 0 && tick < daySegment) setTicker(tick);

        const x = 15.91549430918954 * Math.cos((dayPassedPercent * 3.6 + 90) * (Math.PI / 180));
        const y = 15.91549430918954 * Math.sin((dayPassedPercent * 3.6 + 90) * (Math.PI / 180));

        setDx(x);
        setDy(y);
    }

    useEffect(() => {
        if (!current) return;
        if (isTerminated) return;

        calculate();
        const interval = setInterval(() => {
            calculate();
        }, 1000);
        return () => clearInterval(interval);

    }, [current, nightSegment]);

    // eslint-disable-next-line default-case
    switch (status) {
        case "DONE": {
            style = {
                background: "rgba(31,28,59,0.5)",
                border: "1px solid #09ba11",
            }
            done = true;
            break;
        }
        case "CURRENT": {
            if(!isTerminated)
                style = {
                    background: "linear-gradient(180deg, rgba(205,29,205,0.3) 0%, rgba(31,28,59,0.5) 60%)",
                    border: "1px solid #CD1DCD"
                }
            current = true;
            break;
        }
        case "FUTURE": {
            style = {
                background: "rgba(31,28,59,0.5)",
                border: "1px solid #dddd14"
            }
            break;
        }
    }

    return <Card style={style}>
        <Card.Body className={"p-0"}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
                <text x="2" y="6" font-size="4" fill="white" font-weight="400">{name}</text>

                {!done && !current && !!daysToStart &&
                <g>
                    <rect x="11" y="-0.75" dominant-baseline="middle" width="20" height="4" fill="#dddd14" rx="1"
                          ry="1"/>
                    <text x="50%" y="1.75" dominant-baseline="middle" text-anchor="middle" fill="black"
                          font-weight="400" font-size="2.5">starts in {daysToStart} days
                    </text>
                </g>
                }

                <svg x="16" y="8"
                     xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                     viewBox="0 0 196 196" fill="none" stroke-width="1"
                     stroke="white"
                     stroke-linecap="round" stroke-linejoin="round"
                     className="feather feather-sun">
                    <circle cx="12" cy="12" r="5"></circle>
                    <line x1="12" y1="1" x2="12" y2="3"></line>
                    <line x1="12" y1="21" x2="12" y2="23"></line>
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                    <line x1="1" y1="12" x2="3" y2="12"></line>
                    <line x1="21" y1="12" x2="23" y2="12"></line>
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                </svg>

                <text x="22" y="11.5" font-size="3" fill="white" font-weight="400">{lightHours}</text>

                <text x="50%" y="45%" dominant-baseline="middle" text-anchor="middle" font-size="5.5" font-weight="600"
                      fill="white">
                    {current
                        ? <tspan fill={isTerminated?"":"#DE00D1"}>{`${-daysToStart + 1}/${loops}`}</tspan>
                        : <tspan>{loops}</tspan>
                    }
                </text>
                <text x="50%" y="56%" dominant-baseline="middle" text-anchor="middle" font-size="3"
                      fill="#727294">loops
                </text>

                <svg x="16" y="28" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                     viewBox="0 0 196 196" fill="none" stroke="currentColor" stroke-width="1"
                     stroke="white" stroke-linecap="round" stroke-linejoin="round"
                     className="feather feather-moon">
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>

                <text x="22" y="31.75" font-size="3" fill="white" font-weight="400">{nightHours}</text>

                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                        stroke={!!done ? "#09ba11" : "#2A2C55"} stroke-width="2" stroke-linecap="round"/>

                {!done &&
                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                        stroke="#cbccf0" stroke-width="2" stroke-dasharray={daySegment + " " + nightSegment}
                        stroke-dashoffset={dayOffset}
                        stroke-linecap="round"/>
                }

                {current && !isTerminated &&
                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                        stroke={"#DE00D1"} stroke-width="2" stroke-dasharray={ticker + " " + (100 - ticker)}
                        stroke-dashoffset={dayOffset}
                        stroke-linecap="round">

                </circle>
                }

                {current && (ticker > 0 && ticker < daySegment) && !isTerminated &&
                <svg width="7" height="3" x={21 + dx - 3.5} y={21 + dy - 1.5}>
                    <rect x="0" y="0" width="100%" height="100%" fill="#DE00D1" rx="1" ry="1"/>
                    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" font-weight="600"
                          font-size="2" fill="white">
                        {time}
                    </text>
                </svg>
                }

            </svg>
        </Card.Body>
    </Card>;
}
