import {types} from "mobx-state-tree"

const SideBarStore = types.model("LoaderStore",
    {
        isOpen: types.boolean
    })
    .actions((store) => ({
        open() {
            store.isOpen = true;
        },
        close() {
            store.isOpen = false;
        },
        toggle() {
            store.isOpen = !store.isOpen;
        }
    }))

export const sideBarStore = SideBarStore.create({
    isOpen: false
})
