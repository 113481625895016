import {types} from "mobx-state-tree"

const SocketStore = types.model("SocketStore",
    {
        connected: types.boolean
    })
    .actions((store) => ({
        connect() {
            store.connected = true;
        },
        disconnect() {
            store.connected = false;
        }
    }))

export const socketStore = SocketStore.create({
    connected: false
})
