import {apiGhost} from "./restConfig";

export const TOKEN = "token";

export async function login(username, password, confirm) {
    return await apiGhost.post('/auth/login', {
        username,
        password,
        confirm,
        frontend: true
    });
}
