import remoteImg from "../../../images/remote.png";
import {Dropdown} from "react-bootstrap";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {EditRemote} from "./EditRemote";
import swal from "sweetalert";
import {rootStore} from "../../../store/RootStore";

export default observer(function Remote({remote}) {
    const [edit, setEdit] = useState(false);

    return <div className="card bg-secondary-light m-0 mb-1" style={{border: "1px solid rgba(0, 0, 0, 0.5)"}}>
        {edit && <EditRemote remote={remote} show={edit} setShow={setEdit}/>}
        <div className="card-body p-4">
            <div className="row media">

                <span className="col-xl-2 col-lg-2 col-sm-3 col-3" style={{
                    color: "#fff",
                    padding: "2px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "100px"
                }}>
                    <img src={remoteImg} alt={"remote"} width="90%"/>
                    {/*<span className="badge badge-primary badge-sm badge-pill mt-2">online</span>*/}
                </span>

                <div className="col media-body text-white">
                    <h3 className="mb-0 text-white">{remote?.name}</h3>
                    <p className="mb-1 text-muted">{remote?.description}</p>
                </div>

                <Dropdown className="dropdown text-sans-serif">
                    <Dropdown.Toggle
                        variant=""
                        className="i-false sharp"
                        type="button"
                        id="order-dropdown-0"
                        data-toggle="dropdown"
                        data-boundary="viewport"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                            <span>
                              <i className="fa fa-bars"/>
                            </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="dropdown-menu dropdown-menu-right border py-0"
                        aria-labelledby="order-dropdown-0"
                    >
                        <div className="py-2">

                            <span className="dropdown-item cursor-pointer text-success" onClick={() => {
                                setEdit(true)
                            }}>
                                <i className="fa fa-pencil mr-2"/>Rename
                            </span>

                            <div className="dropdown-divider"/>

                            {!!remote.room &&
                                <span className="dropdown-item cursor-pointer text-danger" onClick={() => {
                                    swal({
                                        title: "Are you sure?",
                                        text: "Relocation of the remote may affect current projects and cultivation cycles",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    }).then((willDelete) => {
                                        if (willDelete) {
                                            rootStore.changeRemoteRoom(remote, null);
                                            swal("Remote has been removed from the room!", {
                                                icon: "success",
                                            });
                                        }
                                    })
                                }}>
                                <i className="fa fa-trash mr-2"/>Remove from growing area
                            </span>}

                            {
                                !remote.room &&
                                <span className="dropdown-item cursor-pointer text-danger" onClick={() => {
                                    swal({
                                        title: "Are you sure?",
                                        // text: "Relocation of the remote may affect current projects and cultivation cycles",
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                        .then((willDelete) => {
                                            if (willDelete) {
                                                rootStore.deleteRemote(remote?.id);
                                                swal("Remote has been deleted!", {
                                                    icon: "success",
                                                });
                                            }
                                        })
                                }}>
                                    <i className="fa fa-trash mr-2"/>Delete remote
                                </span>
                            }


                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </div>;
});
