import React, {Suspense, useEffect} from "react";
import Index from './jsx/index';

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./scss/main.scss";
import {observer} from "mobx-react-lite";

import {authStore} from "./store/AuthStore";
import Login from "./jsx/pages/Login";

export default observer(function App() {

    useEffect(() => {}, []);

    if(authStore.auth) {
        return(
            <div>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="waviy">
                            <span style={{'--i': 1}}>L</span>
                            <span style={{'--i': 2}}>o</span>
                            <span style={{'--i': 3}}>a</span>
                            <span style={{'--i': 4}}>d</span>
                            <span style={{'--i': 5}}>i</span>
                            <span style={{'--i': 6}}>n</span>
                            <span style={{'--i': 7}}>g</span>
                            <span style={{'--i': 8}}>.</span>
                            <span style={{'--i': 9}}>.</span>
                            <span style={{'--i': 10}}>.</span>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </div>
        );

    }else{
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="waviy">
                            <span style={{'--i': 1}}>L</span>
                            <span style={{'--i': 2}}>o</span>
                            <span style={{'--i': 3}}>a</span>
                            <span style={{'--i': 4}}>d</span>
                            <span style={{'--i': 5}}>i</span>
                            <span style={{'--i': 6}}>n</span>
                            <span style={{'--i': 7}}>g</span>
                            <span style={{'--i': 8}}>.</span>
                            <span style={{'--i': 9}}>.</span>
                            <span style={{'--i': 10}}>.</span>
                        </div>
                    </div>
                }
                >
                    <Login/>
                </Suspense>
            </>
        );
    }
})
