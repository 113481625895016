import React, {useContext, useEffect, useState} from "react";

/// React router dom
import {Route, Switch} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";

/// Pages
import Login from "./pages/Login";
import {ThemeContext} from "../context/ThemeContext";
import ScrollToTop from "./layouts/ScrollToTop";
import History from "./pages/History";
import Devices from "./pages/Devices";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import {authStore} from "../store/AuthStore";
import Profile from "./pages/Profile";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Projects/Project";
import ProjectRoom from "./pages/Projects/ProjectRoom";
import {createRootStore} from "../store/RootStore";
import {socket} from "../api/socket";
import Programs from "./pages/Programs/Programs";
import CreateUpdateProtocol from "./pages/Programs/CreateUpdateProtocol";
import CyclePage from "./pages/Cycle/CyclePage";
import PhasePage from "./pages/Cycle/PhasePage";

const Markup = () => {

    useEffect(() => {
        createRootStore();
    }, []);

    const {menuToggle} = useContext(ThemeContext);
    const routes = [
        /// pages
        {url: "login", component: Login},
        {url: "profile", component: Profile},
        {url: "", component: Projects},
        {url: "projects", component: Projects},
        {url: "projects/:id", component: Project},
        {url: "projects/:projectID/room/:roomID", component: ProjectRoom},
        {url: "projects/:projectID/room/:roomID/cycle/:cycleID", component: CyclePage},
        {url: "projects/:projectID/room/:roomID/cycle/:cycleID/phase/:phaseID", component: PhasePage},
        {url: "history", component: History},
        {url: "programs", component: Programs},
        {url: "programs/:id", component: CreateUpdateProtocol},
        {url: "devices", component: Devices},
        {url: "reports", component: Reports},
        {url: "users", component: Users, admin: true},
    ];

    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let pagePath = path.split("-").includes("page");

    const [height, setHeight] = useState(window.screen.height - 120);

    useEffect(() => {
        const header = document.querySelector(".header");
        setHeight(window.screen.height - header.clientHeight);
    }, [window.screen.height])

    useEffect(() => {
        socket.connect();

        socket.on("connect", () => console.log("connect"))
        socket.on("disconnect", reason => console.log("disconnect", reason))

        return () => socket.disconnect();
    }, [])

    return (
        <>
            <div
                id={`${!pagePath ? "main-wrapper" : ""}`}
                className={`${!pagePath ? "show" : "mh100vh"}  ${
                    menuToggle ? "menu-toggle" : ""
                }`}
            >
                {!pagePath && <Nav/>}

                <div className={`${!pagePath ? "content-body" : ""}`} style={{ minHeight: height}}>
                    <div
                        className={`${!pagePath ? "container-fluid" : ""}`}>
                        <Switch>
                            {routes.map((data, i) => (
                                ((!!data.admin && authStore.admin) || !data.admin ) &&
                                <Route
                                    key={i}
                                    exact
                                    path={`/${data.url}`}
                                    component={data.component}
                                />
                            ))}
                        </Switch>
                    </div>
                </div>
            </div>
            <ScrollToTop/>
        </>
    );
};

export default Markup;
