import {Col, FormControl, ListGroup, Row} from "react-bootstrap";
import Nouislider from "nouislider-react";
import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {toF} from "../../utils/TemperatureConverter";

ParameterSetupComponent.propTypes = {
    parameter: PropTypes.any,
    update: PropTypes.func,
    changed: PropTypes.func,
    readOnly: PropTypes.bool,
    fahrenheit: PropTypes.bool,
};

export function ParameterSetupComponent({parameter, update, changed, readOnly, fahrenheit}) {

    const [max, setMax] = useState(convertToF(parameter.max));
    const [min, setMin] = useState(convertToF(parameter.min));

    convertToF(parameter.boundaries[0])

    const boundaries = [convertToF(parameter.boundaries[0]), convertToF(parameter.boundaries[1])];

    const onChange = (render, handle, value, un, percent) => {
        setMin(+value[0].toFixed(0));
        setMax(+value[1].toFixed(0));
    }

    useEffect(() => {
        setMin(convertToF(parameter.min));
        setMax(convertToF(parameter.max));
    }, [parameter])

    useEffect(() => {
        parameter.min = min;
        parameter.max = max;
        update(parameter);
    }, [max, min])

    function convertToF(value) {
        return +(fahrenheit? toF(value) : value).toFixed(0)
    }

    return <ListGroup.Item>
        <Row>
            <Col md={12} xl={6} className={"my-2"}>
                <div className="d-flex align-items-center">
                    <div className="mini-ic">
                        {parameter.icon}
                    </div>
                    <div className="ml-3">
                        <h5 className="mb-0 pt-1 font-w500 text-black">
                            {parameter.title}
                        </h5>
                        {!!parameter.hint &&
                        <span className={"text-muted"}>{parameter.hint}</span>}
                    </div>
                </div>
            </Col>

            <Col md={12} xl={6}>
                <Row>
                    <Col className={"my-2"}>
                        <div className="d-flex align-items-center">
                            <div className="mini-ic">
                                <i className="las la-download text-info" aria-hidden="true"/>
                            </div>
                            <div className="ml-1">
                                <span className={"text-muted"}>Minimum</span>
                                <FormControl className={"p-0 text-white"} plaintext type="number" size={"sm"}
                                             readOnly={readOnly}
                                             min={0} max={9999}
                                             value={min}
                                             maxlength={4}
                                             style={{width: "4em"}}
                                             onChange={e => {
                                                 setMin(e.target.value);
                                                 changed();
                                             }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col className={"my-2"}>
                        <div className="d-flex align-items-center">
                            <div className="mini-ic">
                                <i className="las la-download text-danger fa-rotate-180"
                                   aria-hidden="true"/>
                            </div>
                            <div className="ml-1">
                                <span className={"text-muted"}>Maximum</span>
                                <FormControl className={"p-0 text-white"} plaintext type="number" size={"sm"}
                                             readOnly={readOnly}
                                             min={0} max={9999}
                                             value={max}
                                             maxlength={4}
                                             style={{width: "4em"}}
                                             onChange={e => {
                                                 setMax(e.target.value);
                                                 changed();
                                             }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>

        <div className="slider pb-5" id="ClickAblePips">
            <Nouislider
                connect
                start={[min, max]}
                pips={{mode: "count", values: 11}}
                clickablePips
                behaviour="tap"
                disabled={readOnly}
                range={{
                    min: boundaries[0],
                    max: boundaries[1],
                }}
                animate={false}
                onUpdate={onChange}
                onSlide={changed}
            />
        </div>

    </ListGroup.Item>;
}
