import React, {useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {rootStore} from "../../../store/RootStore";

export function CreateProjectModal({show, setShow}) {

    const handleClose = () => setShow(false);
    const [name, setName] = useState("");
    const [rooms, setRooms] = useState([]);
    const [error, setError] = useState();

    function create() {
        rootStore.createProject(name, rooms)
            .then(__ => handleClose())
            .catch(reason => setError(reason.message));
    }

    return <Modal className="modal fade" show={show} onHide={setShow}>

        <Modal.Header closeButton>
            <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onKeyPress={event => {
                if (event.code === "Enter") {
                    event.preventDefault();
                    create();
                }
            }}>
                <Form.Group>
                    <Form.Label className="text-black font-w500">Location Name</Form.Label>
                    <Form.Control maxLength="50" aria-describedby="nameHelp"
                                  onChange={e => setName(e.target.value)}/>
                    <Form.Text id="nameHelp" muted>
                        Location Name must be less than 50 characters long, and
                        must not contain special characters, or emoji.
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="text-black font-w500">Choose growing area(s) for Project:</Form.Label>
                    <DropdownMultiselect
                        className="text-black font-w500"
                        buttonClass="btn-dark"
                        options={rootStore.rooms?.map(room => {
                            return {
                                key: room.id,
                                label: room.name
                            }
                        })}
                        handleOnChange={(selected) => {
                            setRooms(selected)
                        }}
                    />
                </Form.Group>
            </Form>

            {error && (
                <Alert
                    variant="danger"
                    className="solid alert-dismissible fade show"
                >
                    <strong>Error.</strong> {error}
                </Alert>
            )}
        </Modal.Body>

        <Modal.Footer>
            <Button
                block
                variant="secondary"
                onClick={create}>
                CREATE
            </Button>
        </Modal.Footer>

    </Modal>;
}
