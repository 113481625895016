import {observer} from "mobx-react-lite";
import {Breadcrumb, Button, ButtonGroup, Card, Col, FormControl, FormGroup, ListGroup, Row} from "react-bootstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Item, rootStore} from "../../../store/RootStore";
import {Phase} from "../../components/cycle/Phase";
import "nouislider/distribute/nouislider.css"
import {createViewModel} from "mobx-utils";
import {ParameterSetupComponent} from "../../components/phase/ParameterSetupComponent";
import swal from "sweetalert";
import {ItemsLightTable} from "../../components/phase/ItemsLightTable";
import {minutesToHuman} from "../../utils/TimeUtils";
import {profileStore} from "../../../store/ProfileStore";
import {toC} from "../../utils/TemperatureConverter";

export default observer(function PhasePage() {

    const history = useHistory();

    const {projectID} = useParams();
    const {roomID} = useParams();
    const {cycleID} = useParams();
    const {phaseID} = useParams();

    const backToCycle = () => {
        // history.push(`/projects/${projectID}/room/${roomID}/cycle/${cycleID}`);
    }

    const store = rootStore;

    const [project, setProject] = useState();
    const [room, setRoom] = useState();
    const [cycle, setCycle] = useState();
    const [phase, setPhase] = useState();
    const [items, setItems] = useState([]);
    const [parameters, setParameters] = useState([]);

    const [changed, setChanged] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!!store.projects && store.projects.length > 0) {
            setProject(store.projects.find(project => project.id === +projectID));
        }
    }, [projectID, store, store.projects.length]);

    useEffect(() => {
        if (!!project)
            setRoom(project?.rooms.find(room => room.id === +roomID));
    }, [project, roomID]);

    useEffect(() => {
        if (!!room && room?.cycles?.length > 0)
            setCycle(room?.cycles.find(cycle => cycle.id === +cycleID));
    }, [cycleID, room, room?.cycles.length])

    useEffect(() => {
        if (!!cycle) {
            setPhase(createViewModel(cycle?.protocol?.phases[+phaseID]));
        }
    }, [cycle, phaseID, cancel]);

    useEffect(() => {
        if (!!phase) {
            console.log("p")
            setItems([...phase?.items.map(item => createViewModel(item))]);
            setParameters([...phase?.parameters.map(param => createViewModel(param))]);
            setReadOnly(phase?.status === "DONE")
        }
    }, [phase]);

    const changeItem = (index, key, value) => {
        const item = items[index];
        item[key] = +value;
        setItems(prevState => {
            const state = [...prevState]
            const item = state[index];
            item[key] = +value;
            return state;
        });
        setChanged(true);
    }

    const deleteItem = (i) => {
        items.splice(i, 1);
        setItems([...items]);
        setChanged(true);
    }

    function convertToC(parameterName, value) {
        return +(parameterName === "T" && profileStore.f? toC(value) : value).toFixed(0)
    }

    const breadcrumbs = <Row className={"page-titles mx-0"}>
        <Col>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link}
                                 linkProps={{to: "/projects/" + projectID}}>{cycle?.projectName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link}
                                 linkProps={{to: "/projects/" + projectID + "/room/" + roomID}}>{cycle?.roomName}</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link}
                                 linkProps={{to: `/projects/${projectID}/room/${roomID}/cycle/${cycleID}`}}>{cycle?.name}</Breadcrumb.Item>
                <Breadcrumb.Item active>{phase?.name || phaseID}</Breadcrumb.Item>
            </Breadcrumb>
        </Col>

        <Col className={"d-flex align-items-center justify-content-end"}>
            <ButtonGroup size="sm">
                <Button disabled={!changed || readOnly} variant={"danger"} className={"mr-1"}
                        onClick={() => {
                            if (error) history.go(0);
                            else {
                                setCancel(!cancel);
                                setChanged(false);
                            }
                        }}>Cancel</Button>
                <Button disabled={!changed || readOnly} variant={"success"} className={"mr-1"}
                        onClick={() => {
                            setReadOnly(true);
                            phase?.apply(cycleID, phaseID, phase, items, parameters)
                                .then(__ => {
                                    setChanged(false);
                                    setReadOnly(false);
                                    setCancel(!cancel);
                                })
                                .catch(reason => {
                                    swal({title: "Error", text: reason?.message, icon: "error"});
                                    setReadOnly(false);
                                    setChanged(true);
                                    setError(true);
                                })
                        }}>Apply</Button>
            </ButtonGroup>
        </Col>

    </Row>;

    function computeDuration() {
        const totalMinutes = items.map(item => item.period).reduce((a, b) => parseInt(a) + parseInt(b), 0);
        return minutesToHuman(totalMinutes);
    }

    const layout = <Row>
        <Col xl={2} lg={12}>
            <Row>
                <Col xs={6} sm={6} lg={4} xl={12} >
                    <Phase phase={phase}/>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <ListGroup className={"list-group-flush"}>
                                <ListGroup.Item className={"d-flex px-0 justify-content-between"}>
                                    <strong>Name</strong>
                                    <FormGroup className={"p-0 m-0"}>
                                        <FormControl type="text" size={"sm"}
                                                     readOnly={readOnly}
                                                     value={phase?.name || ''}
                                                     style={{width: "5em"}} maxlength={3}
                                                     onChange={e => {
                                                         phase.name = e.target.value;
                                                         setChanged(true);
                                                     }}
                                        />
                                    </FormGroup>
                                </ListGroup.Item>

                                <ListGroup.Item className={"d-flex px-0 justify-content-between"}>
                                    <strong>Loops</strong>

                                    <FormGroup className={"p-0 m-0"}>
                                        <FormControl type="number" size={"sm"}
                                                     readOnly={readOnly}
                                                     min={0} max={100}
                                                     value={phase?.loops || 0}
                                                     style={{width: "5em"}}
                                                     onChange={e => {
                                                         phase.loops = +e.target.value;
                                                         setChanged(true);
                                                     }}
                                        />
                                    </FormGroup>
                                </ListGroup.Item>

                                <ListGroup.Item className={"d-flex px-0 justify-content-between"}>
                                    <strong>Loop Duration</strong>
                                    <span className={"mb-0"}>{computeDuration()}</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </Col>

        <Col xl={5}>
            <Card>
                <Card.Header>
                    <Card.Title>Items</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ItemsLightTable items={items} readOnly={readOnly} changeItem={changeItem} deleteItem={deleteItem} />
                    <div className={"d-flex justify-content-end"}>
                        <Button
                            variant="secondary" size={"sm"}
                            className="rounded"
                            onClick={() => {
                                setItems(prevState => {
                                    return [...prevState, createViewModel(Item.create({ch1: 0, ch2: 0, ch3: 0, ch4: 0, period: 0}))];
                                })
                            }}>
                            <i className="fa fa-plus mr-3 scale5" aria-hidden="true"/>Add Item
                        </Button>
                    </div>

                </Card.Body>
            </Card>
        </Col>

        <Col xl={5}>
            <Card>
                <Card.Header>
                    <Card.Title>Parameters</Card.Title>
                </Card.Header>
                <Card.Body className={"p-0"}>
                    <ListGroup className={"list-group-flush"}>
                        {
                            parameters.map(parameter =>
                                <ParameterSetupComponent key={`parameter-${parameter.name}`}
                                                         parameter={parameter}
                                                         changed={() => setChanged(true)}
                                                         readOnly={readOnly}
                                                         fahrenheit={parameter.name === "T" && profileStore.f}
                                                         update={(upd) => {
                                                             upd.min = convertToC(parameter.name, upd.min);
                                                             upd.max = convertToC(parameter.name, upd.max);

                                                             const index = parameters.indexOf(parameter);
                                                             parameters[index] = upd;
                                                             setParameters([...parameters]);
                                                         }}/>)
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
        </Col>
    </Row>

    return (
        <>
            {breadcrumbs}
            {!!phase && layout}
        </>
    )
});
