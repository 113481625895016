import React, {useEffect} from 'react';
import {routeStore} from "../../store/RouteStore";
import {ComingSoon} from "../components/ComingSoon";

const History = () => {

    useEffect(() => {
        routeStore.setLocation("History")
    }, [])

    return(
        <ComingSoon title={"History"}/>
    )
}
export default History;
