import React from "react";
import {LogoutPage} from './Logout';

/// Image
import {Dropdown, Form} from "react-bootstrap";
import {authStore} from "../../../store/AuthStore";
import {routeStore} from "../../../store/RouteStore";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";

export default observer(function Header() {

    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div
                                className="dashboard_bar"
                                style={{textTransform: "capitalize"}}
                            >
                                {routeStore.location}
                            </div>
                        </div>
                        <ul className="navbar-nav header-right main-notification">
                            <Dropdown
                                as="li"
                                className="nav-item dropdown notification_dropdown "
                            >
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link  ai-icon i-false c-pointer "
                                    // href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                >
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                                            fill="#67636D"/>
                                        <path
                                            d="M9.98193 24.5C10.3863 25.2088 10.971 25.7981 11.6767 26.2079C12.3823 26.6178 13.1839 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0289 25.7981 17.6136 25.2088 18.0179 24.5H9.98193Z"
                                            fill="#67636D"/>
                                    </svg>
                                    {/*<span className="badge light text-white bg-primary rounded-circle">4</span>*/}
                                </Dropdown.Toggle>
                            </Dropdown>

                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link i-false c-pointer"
                                    // href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                >
                                    <img src={authStore.avatarUrl} width={20} alt=""/>
                                    <div className="header-info">
                                        <span>{authStore.username}</span>
                                        <small>{authStore.role}</small>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right" className="mt-2">
                                    <Dropdown.Item  as={Link} to={"/profile"}>
                                        <i className={"las la-user-cog scale4 mr-3"}/>Profile
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item as={Link} to={"#"} onClick={()=>authStore.logout()} className={"text-danger"}>
                                        <i className={"las la-sign-out-alt scale4 mr-3"}/>Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
})
