import {Breadcrumb, Button, ButtonGroup, Col, InputGroup, Row, Table} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {newPhaseObject, newProtocolObject} from "../../utils/NewProtocolObject";
import {ProtocolPhase} from "./ProtocolPhase";
import {api} from "../../../api/restConfig";
import {observer} from "mobx-react-lite";
import {capitalize} from "../../utils/StringUtils";

export default observer(function CreateUpdateProtocol() {

    const {id} = useParams();

    const [protocol, setProtocol] = useState();
    const [name, setName] = useState("")
    const [showCulture, setShowCulture] = useState()
    const [showCultivation, setShowCultivation] = useState()

    const [enableSave, setEnableSave] = useState(false);

    function load() {
        if (id === "new") {
            setName("New Growing Protocol");
            setProtocol(newProtocolObject());
            return;
        }
        if (!!id && typeof parseInt(id) === "number") {
            api.get(`program/${id}`).then(res => {
                if (!!res.data) setProtocol(res.data);
            }).catch(reason => console.log(reason))
        }
    }

    useEffect(() => {
        load()
    }, [id]);

    useEffect(() => {
        let savable = !!protocol?.name
            && protocol.phases.length > 0
            && protocol.phases.every(phase => !!phase.name)
            && protocol.phases.every(phase => phase.items.map(i => i.period).reduce((p, c) => p + c, 0) > 0);

        setEnableSave(savable);

        if (!!protocol && !protocol?.name) {
            console.log("no name")
            setName("New Growing Protocol");
            setEnableSave(false);
        } else {
            setName(protocol?.name);
        }

    }, [protocol])

    const TextInput = ({category, label, value, disabled}) => {

        return <InputGroup size={"sm"}>
            <input type="text" className="form-control"
                   defaultValue={value}
                   disabled={disabled}
                   onChange={e => {
                       setProtocol(prevState => {
                           const state = prevState;
                           state[category][label] = e.target.value;

                           console.log(state);
                           return state;
                       })
                   }}/>
        </InputGroup>
    }

    function updatePhase(i) {
        return (updated) => {
            setProtocol(prevState => {
                const state = {...prevState};
                state.phases[i] = updated;
                return state;
            })
        };
    }

    function save() {
        api.post("program", protocol)
            .then(res => {
                if (!!res.data) setProtocol(res.data);
            })
            .catch(err => console.error(err))
    }

    return <>
        <Row className={"page-titles mx-0"}>
            <Col lg={12} className={"p-md-0 mt-2 mt-sm-0"}>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link}
                                     linkProps={{to: "/programs"}}>Programs</Breadcrumb.Item>
                    <Breadcrumb.Item active>{name || ''}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>

            {!protocol?.shared &&
                <Col className={"p-0 d-flex align-items-center justify-content-end"}>
                    <ButtonGroup size="sm">
                        <Button variant={"danger"} className={"mr-1"}
                                onClick={() => {
                                    load()
                                }}>Cancel</Button>
                        <Button disabled={!enableSave} variant={"success"} className={"mr-1"}
                                onClick={() => save()}>Save</Button>
                    </ButtonGroup>
                </Col>
            }
        </Row>

        <Row>
            <Col lg={3}>

                <label>Name</label>
                <InputGroup size={"lg"} className="mb-3">
                    <input type="text" className="form-control"
                           disabled={protocol?.shared}
                           value={protocol?.name}
                           onChange={e => {
                               setName(e.target.value);
                               setProtocol(prevState => {
                                   const state = {...prevState};
                                   state.name = e.target.value;
                                   return state;
                               })
                           }}/>
                </InputGroup>

                <label>Description</label>
                <InputGroup size={"lg"} className="mb-3">
                    <textarea rows={2} className="form-control"
                              disabled={protocol?.shared}
                              maxLength={250}
                              value={protocol?.description}
                              onChange={e => {
                                  setProtocol(prevState => {
                                      const state = {...prevState};
                                      state.description = e.target.value;
                                      return state;
                                  })
                              }}
                    />
                </InputGroup>

                <h4 className={"d-flex justify-content-between cursor-pointer"}
                    onClick={() => setShowCulture(!showCulture)}>
                    Features Of The Culture
                    <i className={`fa ${showCulture ? 'fa-eye' : 'fa-eye-slash'} align-self-center `}/>
                </h4>

                <Table borderless hidden={!showCulture}>
                    <tbody>
                    {Object.entries(protocol?.culture || {}).map(([k, v]) => {
                        return <tr key={k}>
                            <td>{capitalize(k)}</td>
                            <td><TextInput category={"culture"} disabled={protocol?.shared} label={k} value={v}/></td>
                        </tr>
                    })}
                    </tbody>
                </Table>

                <h4 className={"d-flex justify-content-between mt-3 cursor-pointer"}
                    onClick={() => setShowCultivation(!showCultivation)}>
                    Features Of The Cultivation
                    <i className={`fa ${showCultivation ? 'fa-eye' : 'fa-eye-slash'} align-self-center `}/>
                </h4>

                <Table borderless hidden={!showCultivation}>
                    <tbody>
                    {Object.entries(protocol?.cultivation || {}).map(([k, v]) => {
                        return <tr key={k}>
                            <td>{capitalize(k)}</td>
                            <td><TextInput category={"cultivation"} disabled={protocol?.shared} label={k} value={v}/>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>

            </Col>

            <Col lg={9}>

                <Row className={"d-flex align-items-end"}>
                    <Col>
                        <h4>Phases</h4>
                    </Col>
                    <Col className={"d-flex justify-content-end"}>
                        <Button
                            variant="secondary" size={"sm"}
                            hidden={protocol?.shared}
                            className="rounded"
                            onClick={() => {
                                setProtocol(prevState => {
                                    const state = {...prevState};
                                    state.phases = [...state.phases, newPhaseObject()]
                                    return state;
                                })
                            }}>
                            <i className="fa fa-plus scale3 mr-3" aria-hidden="true"/>Add Phase
                        </Button>
                    </Col>
                </Row>

                <hr className={"mt-1"}/>

                {protocol?.phases.map((phase, i) => {
                    return <ProtocolPhase key={`protocol-phase-${i}`}
                                          phase={phase}
                                          i={i}
                                          first={i === 0}
                                          last={i === protocol?.phases.length - 1}
                                          readOnly={protocol?.shared}
                                          change={updatePhase(i)}
                                          clone={() => setProtocol(prevState => {
                                              const state = {...prevState};
                                              const clone = JSON.parse(JSON.stringify(phase));
                                              clone.name = null;
                                              state.phases = [...prevState.phases, clone];
                                              return state;
                                          })}
                                          remove={() => {
                                              setProtocol(prevState => {
                                                  const state = {...prevState};
                                                  const array = [...prevState.phases];
                                                  array.splice(i, 1);
                                                  state.phases = array;
                                                  return state;
                                              })
                                          }}
                                          up={() => {
                                              setProtocol(prevState => {
                                                  const state = {...prevState};
                                                  const array = [...prevState.phases];
                                                  const element = array[i];
                                                  array.splice(i, 1);
                                                  array.splice(i - 1, 0, element);
                                                  state.phases = array;
                                                  return state;
                                              })
                                          }}
                                          down={() => {
                                              setProtocol(prevState => {
                                                  const state = {...prevState};
                                                  const array = [...prevState.phases];
                                                  const element = array[i];
                                                  array.splice(i, 1);
                                                  array.splice(i + 1, 0, element);
                                                  state.phases = array;
                                                  return state;
                                              })
                                          }}
                    />
                })}


            </Col>

        </Row>


    </>
})
